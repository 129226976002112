import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter";
import { IHoanPhiCalculateRequest } from './../../models/request/hoan-phi/HoanPhiCalculateRequest';
import { sf_hinhthucnopbom } from './../../models/response/category/sf_hinhthucnopbom';
import { ts_hocsinh_viewmodel } from './../../models/response/hocsinh/ts_hocsinh';
import { eHoanPhiActionTypeIds } from './../action-types/hoanPhiActionTypes';
import { baseAction } from "./BaseActions";

export const hoanPhiActions = {
    changeFilter: (filter: NamHocTruongKhoiHeFilter) => baseAction(eHoanPhiActionTypeIds.CHANGE_FILTER, filter),
    changeHinhThucNopBomSelected: (data: sf_hinhthucnopbom) => baseAction(eHoanPhiActionTypeIds.CHANGE_HINHTHUCNOPBOM, data),

    loadHocSinhStart: (filter: NamHocTruongKhoiHeFilter) => baseAction(eHoanPhiActionTypeIds.LOAD_HOCSINH_START, filter),
    loadHocSinhSuccess: (data: ts_hocsinh_viewmodel[]) => baseAction(eHoanPhiActionTypeIds.LOAD_HOCSINH_SUCCESS, data),
    loadHocSinhError: (err: string) => baseAction(eHoanPhiActionTypeIds.LOAD_HOCSINH_ERROR, err),

    changeHocSinhSelectedIds: (ids: number[]) => baseAction(eHoanPhiActionTypeIds.CHANGE_HOCSINH_SELECTED_IDS, ids),

    calculateStart: (request: IHoanPhiCalculateRequest) => baseAction(eHoanPhiActionTypeIds.CALCULATE_START, request),
    calculateSuccess: () => baseAction(eHoanPhiActionTypeIds.CALCULATE_SUCCESS, undefined),
    calculateError: (err: string) => baseAction(eHoanPhiActionTypeIds.CALCULATE_ERROR, err),
}