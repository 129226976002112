import { sf_mucphaithu_search_rq } from "../../models/request/tonghop/sf_mucphaithu_search_rq"
export enum TongHopActionTypeIds {
    TONG_HOP_LOAD_START = "TONG_HOP_LOAD_START",
    TONG_HOP_LOAD_SUCCESS = "TONG_HOP_LOAD_SUCCESS",
    TONG_HOP_LOAD_ERROR = "TONG_HOP_LOAD_ERROR",
    TONG_HOP_CHANGE_REQUEST = "TONG_HOP_CHANGE_REQUEST"
}

export interface TongHopLoadStart {
    type: TongHopActionTypeIds.TONG_HOP_LOAD_START,
    payload: sf_mucphaithu_search_rq
}

export interface TongHopLoadSucess {
    type: TongHopActionTypeIds.TONG_HOP_LOAD_SUCCESS,
    payload: any[]
}
export interface TongHopLoadError {
    type: TongHopActionTypeIds.TONG_HOP_LOAD_ERROR,
    payload: string
}
export interface TongHopChangeRequest {
    type: TongHopActionTypeIds.TONG_HOP_CHANGE_REQUEST,
    payload: sf_mucphaithu_search_rq

}
export type TongHopActionTypes = TongHopLoadStart | TongHopLoadSucess | TongHopLoadError | TongHopChangeRequest