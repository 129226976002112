import { HoanTraTienAnSelectRequest } from './../models/request/hoan-tra-tien-an/HoanTraTienAnSelectRequest';
import { NamHocTruongKhoiHeFilter } from "../models/namHocTruongKhoiHeFilter";
import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_hachtoan_congno_import_rq } from "../models/request/hach-toan-cong-no/sf_hachtoan_congno_import_rq";
import { sf_hachtoan_congno_select_by_hocsinh_rq } from "../models/request/hach-toan-cong-no/sf_hachtoan_congno_select_by_hocsinh_rq";
import { sf_hachtoan_congno } from "../models/response/hach-toan-cong-no/sf_hachtoan_congno";
import { apiClient } from "./apiClient";

export const SF_HOANTRA_TIENAN_SELECT_ALL_API = "sf_hoantra_tienan/select";
export const SF_HOANTRA_TIENAN_INSERT_API = "sf_hoantra_tienan/insert";
export const SF_HOANTRA_TIENAN_UPDATE_API = "sf_hoantra_tienan/update";
export const SF_HOANTRA_TIENAN_DELETE_API = "sf_hoantra_tienan/delete";
export const SF_HOANTRA_TIENAN_GET_SHEET_API = "sf_hoantra_tienan/get_sheets";
export const SF_HOANTRA_TIENAN_READ_FILE_API = "sf_hoantra_tienan/read_file";
export const SF_HOANTRA_TIENAN_IMPORT_API = "sf_hoantra_tienan/import";

export const SF_HOANTRA_TIENAN_SELECT_BY_HOSINH_API = "sf_hoantra_tienan/select_by_hocsinh";




export const hoanTraTienAnApi = {
    select: (filter: HoanTraTienAnSelectRequest) => {
        return apiClient.post(`${SF_HOANTRA_TIENAN_SELECT_ALL_API}`, filter)
    },
    insert: (data: sf_hachtoan_congno) => {
        return apiClient.post(`${SF_HOANTRA_TIENAN_INSERT_API}`, data)
    },
    update: (data: sf_hachtoan_congno) => {
        return apiClient.post(`${SF_HOANTRA_TIENAN_UPDATE_API}`, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_HOANTRA_TIENAN_DELETE_API}`, model)
    },
    getSheets: (file_name_uploaded: string) => apiClient.post(`${SF_HOANTRA_TIENAN_GET_SHEET_API}?file_name_uploaded=${file_name_uploaded}`),
    readFiles: (rq: sf_hachtoan_congno_import_rq) => apiClient.post(`${SF_HOANTRA_TIENAN_READ_FILE_API}`, rq),
    import: (rq: sf_hachtoan_congno_import_rq) => apiClient.post(SF_HOANTRA_TIENAN_IMPORT_API, rq),
    select_by_hocsinh: (rq: sf_hachtoan_congno_select_by_hocsinh_rq) =>
        apiClient.post(SF_HOANTRA_TIENAN_SELECT_BY_HOSINH_API, rq)
}
