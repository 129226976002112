import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { BOMActionTypeIds, BOMActionTypes } from "../action-types/bomActionTypes";
import { BOMPageState } from "../page-state-model/bomPageState";
const initialState: BOMPageState = {
    sf_boms: [],
    sf_bom_selected_ids: [],
    status: PageBaseStatus.is_completed,
    dm_he_id: localStorageHelper.getDmHeId(),
    dm_khoi_id: localStorageHelper.getDmKhoiId(),
    dm_truong_id: localStorageHelper.getDmTruongId(),
    nam_hoc: localStorageHelper.getNamHoc()
}
export const BOMReducer = (state: BOMPageState = initialState, action: BOMActionTypes): BOMPageState => {
    switch (action.type) {
        case BOMActionTypeIds.BOM_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case BOMActionTypeIds.BOM_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_boms: action.payload
            }
        case BOMActionTypeIds.BOM_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case BOMActionTypeIds.BOM_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_bom_editing: action.payload
            }
        case BOMActionTypeIds.BOM_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false
            }
        case BOMActionTypeIds.BOM_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case BOMActionTypeIds.BOM_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case BOMActionTypeIds.BOM_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case BOMActionTypeIds.BOM_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công BOM ${action.payload.ma_bom}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                sf_bom_editing: undefined,
                is_show_detail_modal: false
            }
        case BOMActionTypeIds.BOM_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case BOMActionTypeIds.BOM_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case BOMActionTypeIds.BOM_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} BOM`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case BOMActionTypeIds.BOM_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case BOMActionTypeIds.BOM_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_bom_selected_ids: action.payload
            }
        case BOMActionTypeIds.BOM_NAMHOC_CHANGED:
            return {
                ...state,
                nam_hoc: action.payload
            }
        case BOMActionTypeIds.BOM_HE_CHANGED:
            return {
                ...state,
                dm_he_id: action.payload
            }
        case BOMActionTypeIds.BOM_KHOI_CHANGED:
            return {
                ...state,
                dm_khoi_id: action.payload,
                dm_he_id: 0
            }
        case BOMActionTypeIds.BOM_TRUONG_CHANGED:
            return {
                ...state,
                dm_truong_id: action.payload,
                dm_khoi_id: 0,
                dm_he_id: 0
            }
        case BOMActionTypeIds.BOM_SHOW_BOMCHIETIET_MODAL: {
            return {
                ...state,
                is_show_bomchitiet_modal: true,
                sf_bom_editing: action.payload
            }
        }
        case BOMActionTypeIds.BOM_CLOSE_BOMCHITIET_MODAL:
            return {
                ...state,
                is_show_bomchitiet_modal: false,
                sf_bom_editing: undefined
            }
        case BOMActionTypeIds.BOM_SHOW_COPY_MODAL: {
            return {
                ...state,
                is_show_copy_modal: true,
                sf_bom_editing: action.payload

            }
        }
        case BOMActionTypeIds.BOM_CLOSE_COPY_MODAL:
            return {
                ...state,
                is_show_copy_modal: false,

            }
        case BOMActionTypeIds.BOM_SHOW_COPY_NAMHOC_MODAL: {
            return {
                ...state,
                is_show_copy_namhoc_modal: true,

            }
        }
        case BOMActionTypeIds.BOM_CLOSE_COPY_NAMHOC_MODAL:
            return {
                ...state,
                is_show_copy_namhoc_modal: false,

            }
        case BOMActionTypeIds.BOM_COPY_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case BOMActionTypeIds.BOM_COPY_SUCCESS:
            showNotify({ message: `Sao chép thành công`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_copy_modal: false
            }
        case BOMActionTypeIds.BOM_COPY_ERR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        default:
            return state;
    }
}