import { sf_hachtoan_congno_select_by_hocsinh_rq } from "../../../models/request/hach-toan-cong-no/sf_hachtoan_congno_select_by_hocsinh_rq"
import { sf_tbtp_remove_hachtoan_rq } from "../../../models/request/tbtp/sf_tbtp_remove_hachtoan_rq"
import { sf_hachtoan_congno, sf_hachtoan_congno_viewmodel } from "../../../models/response/hach-toan-cong-no/sf_hachtoan_congno"

export enum EditTBTPHachToanActionIds {

    EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_START = "EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_START",
    EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_SUCESS = "EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_SUCESS",
    EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_ERROR = "EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_ERROR",


    EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_START = "EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_START",
    EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_SUCCESS = "EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_SUCCESS",
    EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_ERROR = "EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_ERROR",

    EDIT_TBTP_HACHTOAN_SHOW_ADD_MODAL = "EDIT_TBTP_HACHTOAN_SHOW_ADD_MODAL",
    EDIT_TBTP_HACHTOAN_CLOSE_ADD_MODAL = "EDIT_TBTP_HACHTOAN_CLOSE_ADD_MODAL",

    EDIT_TBTP_HACHTOAN_SHOW_DELETE_CONFIRM = "EDIT_TBTP_HACHTOAN_SHOW_DELETE_CONFIRM",
    EDIT_TBTP_HACHTOAN_CLOSE_DELETE_CONFIRM = "EDIT_TBTP_HACHTOAN_CLOSE_DELETE_CONFIRM",

    EDIT_TBTP_HACHTOAN_DELTE_START = "EDIT_TBTP_HACHTOAN_DELTE_START",
    EDIT_TBTP_HACHTOAN_DELTE_SUCCESS = "EDIT_TBTP_HACHTOAN_DELTE_SUCCESS",
    EDIT_TBTP_HACHTOAN_DELTE_ERROR = "EDIT_TBTP_HACHTOAN_DELTE_ERROR",


}

export interface EditTBTPHachToanLoadHachToanAddedStart {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_START,
    payload: number
}


export interface EditTBTPHachToanLoadHachToanAddedSuccess {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_SUCESS,
    payload: sf_hachtoan_congno[]
}


export interface EditTBTPHachToanLoadHachToanAddedError {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_ADDED_ERROR,
    payload: string
}



export interface EditTBTPHachToanLoadHachToanPendingStart {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_START,
    payload: sf_hachtoan_congno_select_by_hocsinh_rq
}



export interface EditTBTPHachToanLoadHachToanPendingSuccess {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_SUCCESS,
    payload: sf_hachtoan_congno_viewmodel[]
}

export interface EditTBTPHachToanLoadHachToanPendingError {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_LOAD_HACHTOAN_PENDING_ERROR,
    payload: string
}

export interface EditTBTPHachToanShowAddModel {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_SHOW_ADD_MODAL,
}

export interface EditTBTPHachToanCloseAddModel {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_CLOSE_ADD_MODAL,
}


export interface EditTBTPHachToanShowDeleteConfirm {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_SHOW_DELETE_CONFIRM,
    payload: number
}


export interface EditTBTPHachToanCloseDeleteConfirm {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_CLOSE_DELETE_CONFIRM,
}



export interface EditTBTPHachToanDeleteStart {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_DELTE_START,
    payload: sf_tbtp_remove_hachtoan_rq
}

export interface EditTBTPHachToanDeleteSuccess {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_DELTE_SUCCESS,
}


export interface EditTBTPHachToanDeleteError {
    type: EditTBTPHachToanActionIds.EDIT_TBTP_HACHTOAN_DELTE_ERROR,
    payload: string
}


export type EditTBTPHachToanActionTypes =
    EditTBTPHachToanLoadHachToanAddedStart | EditTBTPHachToanLoadHachToanAddedSuccess | EditTBTPHachToanLoadHachToanAddedError |
    EditTBTPHachToanLoadHachToanPendingStart | EditTBTPHachToanLoadHachToanPendingSuccess | EditTBTPHachToanLoadHachToanPendingError |
    EditTBTPHachToanShowAddModel | EditTBTPHachToanCloseAddModel |
    EditTBTPHachToanShowDeleteConfirm | EditTBTPHachToanCloseDeleteConfirm |
    EditTBTPHachToanDeleteStart | EditTBTPHachToanDeleteSuccess | EditTBTPHachToanDeleteError