import { showNotify } from './../../helpers/toast';
import { SaleSetDichVuActionTypes } from './../action-types/saleSetRegisterDichVuActionTypes';
import { eSaleSetDichVuTypeIds } from "../action-types/saleSetRegisterDichVuActionTypes";
import { ISaleSetDichVuPageState } from "../page-state-model/saleSetRegisterDichVuPageState";
import { PageBaseStatus } from '../../models/pageBaseStatus';
import { localStorageHelper } from '../../helpers/localStorage';

const iniState: ISaleSetDichVuPageState = {
    filter: {
        nam_hoc: localStorageHelper.getNamHoc(),
        dm_he_id: localStorageHelper.getDmHeId(),
        dm_khoi_id: localStorageHelper.getDmKhoiId(),
        dm_truong_id: localStorageHelper.getDmTruongId(),
    },
    sf_saleset_register_dichvus: [],
    ts_hocsinhs: [],
    status: PageBaseStatus.is_not_initial
}

export const saleSetDichVuReducer = (state: ISaleSetDichVuPageState = iniState, action: SaleSetDichVuActionTypes)
    : ISaleSetDichVuPageState => {
    switch (action.type) {
        case eSaleSetDichVuTypeIds.LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            };
        case eSaleSetDichVuTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_saleset_register_dichvus: action.payload
            };
        case eSaleSetDichVuTypeIds.LOAD_ERRROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            };
        case eSaleSetDichVuTypeIds.LOAD_HOCSINH_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            };
        case eSaleSetDichVuTypeIds.LOAD_HOCSINH_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                ts_hocsinhs: action.payload
            };
        case eSaleSetDichVuTypeIds.LOAD_ERRROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            };
        case eSaleSetDichVuTypeIds.CHANGE_FILTER:
            return {
                ...state,
               filter: action.payload
            };
        default:
            return {
                ...state
            };
    }
}