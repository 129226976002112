import { call, put, takeEvery } from "redux-saga/effects";
import { categorySourceApi } from "../../api/categorySourceApi";
import { IBaseResponeModel } from "../../models/response/base-response";
import { CategorySourceActionTypeIds } from "../action-types/categorySourceActionTypes";
import { loadDmTruongKhoiHeSuccessAction, loadDmTruongSuccessAction, loadDmTruongTreeViewLoadSuccessAction, loadHeSuccessAction, loadKhoiSucessAction, loadNamHocSuccessAction, loadSfHinhThucNopBomSuccessAction } from "../actions/categorySourceActions";

export function* categorySourceSaga(): any {
    yield takeEvery(CategorySourceActionTypeIds.DM_TRUONG_LOAD_START, loadTruongWorker)
    yield takeEvery(CategorySourceActionTypeIds.DM_NAMHOC_LOAD_START, loadNamHocWorker)
    yield takeEvery(CategorySourceActionTypeIds.DM_KHOI_LOAD_START, loadKhoiWorker)
    yield takeEvery(CategorySourceActionTypeIds.DM_HE_LOAD_START, loadHeWorker)
    yield takeEvery(CategorySourceActionTypeIds.DM_TRUONG_TREEVIEW_LOAD_START, loadTruongTreeViewWorker)
    yield takeEvery(CategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_START, loadTruongKhoiHeWorker)
    yield takeEvery(CategorySourceActionTypeIds.SF_HINHTHUCNOPBOM_LOAD_START, loadHinhThucNopBomWorker)

}
function* loadTruongWorker(): any {
    const res: IBaseResponeModel = yield call(categorySourceApi.dm_truongs)
    if (res.is_success) {
        yield put(loadDmTruongSuccessAction(res.data))
    }
}
function* loadNamHocWorker(): any {
    const res: IBaseResponeModel = yield call(categorySourceApi.dm_namhocs)
    if (res.is_success) {
        yield put(loadNamHocSuccessAction(res.data))
    }
}
function* loadKhoiWorker(): any {
    const res: IBaseResponeModel = yield call(categorySourceApi.dm_khois)
    if (res.is_success) {
        yield put(loadKhoiSucessAction(res.data))
    }
}
function* loadHeWorker(): any {
    const res: IBaseResponeModel = yield call(categorySourceApi.dm_hes)
    if (res.is_success) {
        yield put(loadHeSuccessAction(res.data))
    }
}
function* loadTruongTreeViewWorker(): any {
    const res: IBaseResponeModel = yield call(categorySourceApi.dm_truong_treeview)
    if (res.is_success) {
        yield put(loadDmTruongTreeViewLoadSuccessAction(res.data))
    }
}
function* loadTruongKhoiHeWorker(): any {
    const res: IBaseResponeModel = yield call(categorySourceApi.dm_truong_khoi_hes)
    if (res.is_success) {
        yield put(loadDmTruongKhoiHeSuccessAction(res.data))
    }
}
function* loadHinhThucNopBomWorker(): any {
    const res: IBaseResponeModel = yield call(categorySourceApi.sf_hinhthucnopboms)
    if (res.is_success) {
        yield put(loadSfHinhThucNopBomSuccessAction(res.data))
    }
}