import { sf_tbtp_add_khoannop_rq } from "../../../models/request/tbtp/sf_tbtp_add_khoannop_rq"
import { sf_tbtp_remove_khoannop_rq } from "../../../models/request/tbtp/sf_tbtp_remove_khoannop_rq"
import { sf_hinhthucnopbom } from "../../../models/response/category/sf_hinhthucnopbom"
import { sf_tbtp_khoannop_viewmodel } from "../../../models/response/tbtp/sf_tbtp_khoannop"
import { CloseDeleteConfirm, EditTBTPKhoanNopActionTypeIds, HinhThucNopBomLoadError, HinhThucNopBomLoadStart, HinhThucNopBomLoadSuccess, KhoanNopAddError, KhoanNopAddStart, KhoanNopAddSuccess, KhoanNopCloseAddModal, KhoanNopDeleteError, KhoanNopDeleteStart, KhoanNopDeleteSuccess, KhoanNopLoadError, KhoanNopLoadStart, KhoanNopLoadSuccess, KhoanNopShowAddModal, ShowDeleteConfirm } from "../../action-types/edit-tbtp/editTBTPKhoanNopActionTypes"

export const loadKhoanNopStart = (sf_tbtp_id: number): KhoanNopLoadStart => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.LOAD_KHOANNOP_START,
        payload: sf_tbtp_id
    }
}

export const loadKhoanNopSuccess = (payload: sf_tbtp_khoannop_viewmodel[]): KhoanNopLoadSuccess => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.LOAD_KHOANNOP_SUCCESS,
        payload: payload
    }
}

export const loadKhoanNopError = (payload: string): KhoanNopLoadError => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.LOAD_KHOANNOP_ERROR,
        payload: payload
    }
}


export const loadHinhThucNopBomStart = (sf_bom_id: number): HinhThucNopBomLoadStart => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.LOAD_HINHTHUCNOPBOM_START,
        payload: sf_bom_id
    }
}

export const loadHinhThucNopBomSuccess = (payload: sf_hinhthucnopbom[]): HinhThucNopBomLoadSuccess => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.LOAD_HINHTHUCNOPBOM_SUCCESS,
        payload: payload
    }
}

export const loadHinhThucNopBomError = (payload: string): HinhThucNopBomLoadError => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.LOAD_HINHTHUCNOPBOM_ERROR,
        payload: payload
    }
}
export const showDeleteConfirm = (sf_khoannop_id: number): ShowDeleteConfirm => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.SHOW_DELETE_CONFIRM,
        payload: sf_khoannop_id
    }
}

export const closeDeleteConfirm = (): CloseDeleteConfirm => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.CLOSE_DELETE_CONFIRM,

    }
}

export const deleteKhoanNopStart = (rq: sf_tbtp_remove_khoannop_rq): KhoanNopDeleteStart => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_DELETE_START,
        payload: rq
    }
}


export const deleteKhoanNopSuccess = (): KhoanNopDeleteSuccess => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_DELETE_SUCCESS,
    }
}

export const deleteKhoanNopError = (message: string): KhoanNopDeleteError => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_DELETE_ERROR,
        payload: message
    }
}


export const showKhoanNopAddModal = (): KhoanNopShowAddModal => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_SHOW_ADD_MODAL,
    }
}


export const closeKhoanNopAddModal = (): KhoanNopCloseAddModal => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_CLOSE_ADD_MODAL,
    }
}

export const saveKhoanNopStart = (data: sf_tbtp_add_khoannop_rq): KhoanNopAddStart => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_ADD_START,
        payload:data
    }
}

export const saveKhoanNopSuccess = (): KhoanNopAddSuccess => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_ADD_SUCCESS,
    }
}



export const saveKhoanNopError = (message:string): KhoanNopAddError => {
    return {
        type: EditTBTPKhoanNopActionTypeIds.KHOANNOP_ADD_ERROR,
        payload: message
    }
}


