import { sf_tbtp_dukien_viewmodal } from './../../models/response/tbtp/sf_tbtp_dukien_viewmodal';
import { ts_hocsinh } from './../../models/response/hocsinh/ts_hocsinh';
import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter";

export enum DuKienActionTypeIds {
    CHANGE_FILTER = "DUKIEN_CHANGE_FILTER",
    LOAD_DATASOURCE_START = "DUKIEN_LOAD_DATASOURCE_START",
    LOAD_DATASOURCE_SUCCESS = "DUKIEN_LOAD_DATASOURCE_SUCCESS",
    LOAD_DATASOURCE_ERROR = "DUKIEN_LOAD_DATASOURCE_ERROR",

}
export interface DuKienChangeFilter {
    type: DuKienActionTypeIds.CHANGE_FILTER,
    payload: NamHocTruongKhoiHeFilter
}

export interface DuKienLoadDataStart {
    type: DuKienActionTypeIds.LOAD_DATASOURCE_START,
    payload: NamHocTruongKhoiHeFilter
}
export interface DuKienLoadDataSuccess {
    type: DuKienActionTypeIds.LOAD_DATASOURCE_SUCCESS,
    payload: sf_tbtp_dukien_viewmodal
}
export interface DuKienLoadDataError {
    type: DuKienActionTypeIds.LOAD_DATASOURCE_ERROR,
    payload: string
}




export type DuKienActionTypes = DuKienChangeFilter |
DuKienLoadDataStart | DuKienLoadDataSuccess | DuKienLoadDataError