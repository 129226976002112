import { showNotify } from './../../helpers/toast';
import { localStorageHelper } from "../../helpers/localStorage";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { MucThuPhiNgsActionTypeIds, MucThuPhiNgsActionTypes } from './../action-types/mucThuPhiNgsActionTypes';
import { MucThuPhiNgsPageState } from './../page-state-model/mucThiPhiNgsPageState';

const initialState: MucThuPhiNgsPageState = {
    filter: localStorageHelper.getNamHocTruongKhoiFilter(),
    sf_mucthuphi_viewmodels: [],
    status: PageBaseStatus.is_not_initial,
    is_show_add_khoannop_modal: false,
    is_show_remove_khoannop_modal: false,
    is_show_sale_set_confirm: false
}
export const mucThuPhiNgsReducer = (state: MucThuPhiNgsPageState = initialState, action: MucThuPhiNgsActionTypes): MucThuPhiNgsPageState => {
    switch (action.type) {
        case MucThuPhiNgsActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case MucThuPhiNgsActionTypeIds.SHOW_ADD_KHOANNOP_MODAL:
            return {
                ...state,
                is_show_add_khoannop_modal: true
            }
        case MucThuPhiNgsActionTypeIds.CLOSE_ADD_KHOANNOP_MODAL:
            return {
                ...state,
                is_show_add_khoannop_modal: false
            }
        case MucThuPhiNgsActionTypeIds.LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case MucThuPhiNgsActionTypeIds.LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_mucthuphi_viewmodels: action.payload
            }
        case MucThuPhiNgsActionTypeIds.LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_mucthuphi_viewmodels: []
            }
        case MucThuPhiNgsActionTypeIds.SAVING_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case MucThuPhiNgsActionTypeIds.SAVING_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_need_reload
            }
        case MucThuPhiNgsActionTypeIds.SAVING_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }
        case MucThuPhiNgsActionTypeIds.SHOW_REMOVE_KHOANNOP_MODAL:
            return {
                ...state,
                is_show_remove_khoannop_modal: true
            }
        case MucThuPhiNgsActionTypeIds.CLOSE_REMOVE_KHOANNOP_MODAL:
            return {
                ...state,
                is_show_remove_khoannop_modal: false,
            }
        case MucThuPhiNgsActionTypeIds.SHOW_SALESET_CONFIRM:
            return {
                ...state,
                is_show_sale_set_confirm: true
            }
        case MucThuPhiNgsActionTypeIds.CLOSE_SALESET_CONFIRM:
            return {
                ...state,
                is_show_sale_set_confirm: false,
            }
        case MucThuPhiNgsActionTypeIds.CREATE_SALE_SET_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case MucThuPhiNgsActionTypeIds.CREATE_SALE_SET_SUCCESS:
            showNotify({ message: "Thành công", type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                is_show_sale_set_confirm: false
            }
        case MucThuPhiNgsActionTypeIds.CREATE_SALE_SET_ERROR:
            showNotify({ message: "Có lỗi", type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                is_show_sale_set_confirm: false
            }
        default:
            return state;
    }

}
