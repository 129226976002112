import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { MucThuPhiActionTypeIds, MucThuPhiActionTypes } from "../action-types/mucThuPhiActionTypes";
import { MucThuPhiPageState } from "../page-state-model/mucThuPhiPageState";
const initialState: MucThuPhiPageState = {
    sf_mucthuphis: [],
    sf_mucthuphi_selected_ids: [],
    status: PageBaseStatus.is_completed,
    nam_hoc: localStorageHelper.getNamHoc(),
    dm_he_id: localStorageHelper.getDmHeId(),
    dm_khoi_id: localStorageHelper.getDmKhoiId(),
    dm_truong_id: localStorageHelper.getDmTruongId()
}
export const mucThuPhiReducer = (state: MucThuPhiPageState = initialState, action: MucThuPhiActionTypes): MucThuPhiPageState => {
    switch (action.type) {
        case MucThuPhiActionTypeIds.MUCTHUPHI_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_mucthuphis: action.payload
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: true,
                sf_mucthuphi_editing: action.payload
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_CLOSE_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: false,
                sf_mucthuphi_editing: undefined
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công mức thu`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                sf_mucthuphi_editing: undefined,
                is_show_detail_modal: false
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case MucThuPhiActionTypeIds.MUCTHUPHI_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} khoản nộp`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_mucthuphi_selected_ids: action.payload
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_NAMHOC_CHANGED:
            return {
                ...state,
                nam_hoc: action.payload
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_HE_CHANGED:
            return {
                ...state,
                dm_he_id: action.payload
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_KHOI_CHANGED:
            return {
                ...state,
                dm_khoi_id: action.payload,
                dm_he_id: 0
            }
        case MucThuPhiActionTypeIds.MUCTHUPHI_TRUONG_CHANGED:
            return {
                ...state,
                dm_truong_id: action.payload,
                dm_khoi_id: 0,
                dm_he_id: 0
            }
        case MucThuPhiActionTypeIds.MUC_THU_PHI_SHOW_COPY_MODAL:
            return {
                ...state,
                is_show_coppy_modal: true
            }
        case MucThuPhiActionTypeIds.MUC_THU_PHI_CLOSE_COPY_MODAL:
            return {
                ...state,
                is_show_coppy_modal: false
            }
        default:
            return state;
    }
}