import { ts_hocsinh_viewmodel } from './../../models/response/hocsinh/ts_hocsinh';
import { sf_hinhthucnopbom } from './../../models/response/category/sf_hinhthucnopbom';
import { NamHocTruongKhoiHeFilter } from '../../models/namHocTruongKhoiHeFilter';
import { IActionBase } from './IActionBase';
import { IHoanPhiCalculateRequest } from '../../models/request/hoan-phi/HoanPhiCalculateRequest';
export enum eHoanPhiActionTypeIds {
    CHANGE_FILTER = "HOAN_PHI_CHANGE_FILTER",

    CHANGE_HINHTHUCNOPBOM = "HOAN_PHI_CHANGE_HINHTHUCNOPBOM",

    LOAD_HOCSINH_START = "HOAN_PHI_LOAD_HOCSINH_START",
    LOAD_HOCSINH_SUCCESS = "HOAN_PHI_LOAD_HOCSINH_SUCCESS",
    LOAD_HOCSINH_ERROR = "HOAN_PHI_LOAD_HOCSINH_ERROR",

    CHANGE_HOCSINH_SELECTED_IDS = "HOAN_PHI_CHANGE_HOCSINH_SELECTED_IDS",

    CALCULATE_START = "HOAN_PHI_ALCULATE_START",
    CALCULATE_SUCCESS = "HOAN_PHI_CALCULATE_SUCCESS",
    CALCULATE_ERROR = "HOAN_PHI_CALCULATE_ERROR"
}
export interface IHoanPhiChangeFilter extends IActionBase<eHoanPhiActionTypeIds.CHANGE_FILTER, NamHocTruongKhoiHeFilter> { }
export interface IHoanPhiChangeHinhThucNopBomSelected extends IActionBase<eHoanPhiActionTypeIds.CHANGE_HINHTHUCNOPBOM, sf_hinhthucnopbom> { }
export interface IHoanPhiLoadHocSinhStart extends IActionBase<eHoanPhiActionTypeIds.LOAD_HOCSINH_START, NamHocTruongKhoiHeFilter> { }
export interface IHoanPhiLoadHocSinhSuccess extends IActionBase<eHoanPhiActionTypeIds.LOAD_HOCSINH_SUCCESS, ts_hocsinh_viewmodel[]> { }
export interface IHoanPhiLoadHocSinhError extends IActionBase<eHoanPhiActionTypeIds.LOAD_HOCSINH_ERROR, string> { }
export interface IHoanPhiChangeHocSinhSelectedIds extends IActionBase<eHoanPhiActionTypeIds.CHANGE_HOCSINH_SELECTED_IDS, number[]> { }

export interface IHoanPhiCalculateStart extends IActionBase<eHoanPhiActionTypeIds.CALCULATE_START, IHoanPhiCalculateRequest> { }
export interface IHoanPhiCalculateSuccess extends IActionBase<eHoanPhiActionTypeIds.CALCULATE_SUCCESS, undefined> { }
export interface IHoanPhiCalculateError extends IActionBase<eHoanPhiActionTypeIds.CALCULATE_ERROR, string> { }

export type IHoanPhiActionTypes = IHoanPhiChangeFilter
    | IHoanPhiChangeHinhThucNopBomSelected
    | IHoanPhiLoadHocSinhStart | IHoanPhiLoadHocSinhSuccess | IHoanPhiLoadHocSinhError
    | IHoanPhiChangeHocSinhSelectedIds
    | IHoanPhiCalculateStart | IHoanPhiCalculateSuccess | IHoanPhiCalculateError