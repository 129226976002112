import React, { createContext, useContext } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { appInfo } from "../AppInfo";
import { initialUserInfo } from "../models/response/account/user-info";
import { RootState } from "../state/reducers";
import { v4 as uuidv4 } from 'uuid';
type CommonContextType = {
    children: React.ReactNode
}

type CommonStoreType = {
    getDataGridStorageKey: (key: string) => string,
    checkAccesiableTo: (apiURL: string) => boolean,
    createUUID: () => string,
    translate: (vi: string) => string
}
const CommonContext = createContext({} as CommonStoreType);
const useCommonContext = () => useContext(CommonContext)
const CommonProvider = ({ children }: CommonContextType) => {
    const user = useSelector((state: RootState) => state.auth.user_info) || initialUserInfo
    const store: CommonStoreType = {
        getDataGridStorageKey: (key: string) => {
            return `dataGridStorangeKey.${appInfo.versionDataGridStorageKey || ""}.${user.id}.${key}`
        },
        checkAccesiableTo: (apiUrl: string) => {
            if (user.apis.find(x => x.url === apiUrl || x.url === `api/${apiUrl}`)) return true;
            return false;
        },
        createUUID: () => {
            return uuidv4();
        },
        translate: () => {
            return "";
        }

    }
    return (
        <CommonContext.Provider value={store}>
            {children}
            <ToastContainer />
        </CommonContext.Provider>
    );
}
export {
    CommonProvider,
    useCommonContext
};

