import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_nhomkhoannop } from "../models/response/category/sf_nhomkhoannop";
import { apiClient } from "./apiClient";

export const SF_NHOMKHOANNOP_SELECT_ALL_API = "sf_nhomkhoannop/select_all";
export const SF_NHOMKHOANNOP_INSERT_API = "sf_nhomkhoannop/insert";
export const SF_NHOMKHOANNOP_UPDATE_API = "sf_nhomkhoannop/update";
export const SF_NHOMKHOANNOP_DELETE_API = "sf_nhomkhoannop/delete";

export const nhomKhoanNopApi = {
    select_all: () => {
        return apiClient.post(SF_NHOMKHOANNOP_SELECT_ALL_API)
    },
    insert: (data: sf_nhomkhoannop) => {
        return apiClient.post(SF_NHOMKHOANNOP_INSERT_API, data)
    },
    update: (data: sf_nhomkhoannop) => {
        return apiClient.post(SF_NHOMKHOANNOP_UPDATE_API, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(SF_NHOMKHOANNOP_DELETE_API, model)
    }
}