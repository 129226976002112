import { HoanTraTienAnSelectRequest } from "../../models/request/hoan-tra-tien-an/HoanTraTienAnSelectRequest";
import { HoanTraTienAnActionTypeIds } from "../action-types/hoanTraTienAnActionTypes";
import { sf_hoantra_tienan_viewmodel } from './../../models/response/hoan-tra-tien-an/sf_hoantra_tienan';
import { HoanTraTienAnChangeFilterAction, HoanTraTienAnChangeSelectedIdsAction, HoanTraTienAnCloseDeleteConfirmAction, HoanTraTienAnCloseDetailModalAction, HoanTraTienAnCloseImportModal, HoanTraTienAnDeleteErrorAction, HoanTraTienAnDeleteStartAction, HoanTraTienAnDeleteSuccessAction, HoanTraTienAnLoadErrorAction, HoanTraTienAnLoadStartAction, HoanTraTienAnLoadSuccessAction, HoanTraTienAnShowDeleteConfirmAction, HoanTraTienAnShowDetailModalAction, HoanTraTienAnShowImportModal } from './../action-types/hoanTraTienAnActionTypes';


export const loadHoanTraTienAnStart = (filter: HoanTraTienAnSelectRequest): HoanTraTienAnLoadStartAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_LOAD_START,
        payload: filter
    }
}

export const loadHoanTraTienAnSuccess = (payload: sf_hoantra_tienan_viewmodel[]): HoanTraTienAnLoadSuccessAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadHoanTraTienAnError = (payload: string): HoanTraTienAnLoadErrorAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_LOAD_ERROR,
        payload: payload
    }
}

export const showHoanTraTienAnDetailModal = (payload?: sf_hoantra_tienan_viewmodel): HoanTraTienAnShowDetailModalAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_SHOW_DETAIL_MODAL,
        payload: payload
    }
}
export const closeHoanTraTienAnDetailModal = (): HoanTraTienAnCloseDetailModalAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CLOSE_DETAIL_MODAL,
    }
}

export const showHoanTraTienAnDeleteConfirm = (): HoanTraTienAnShowDeleteConfirmAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_SHOW_DELETE_CONFIRM,
    }
}
export const closeHoanTraTienAnDeleteConfirm = (): HoanTraTienAnCloseDeleteConfirmAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CLOSE_DELETE_CONFIRM,
    }
}

export const deleteHoanTraTienAnStart = (payload: number[]): HoanTraTienAnDeleteStartAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_DELETE_START,
        payload: payload
    }
}

export const deleteHoanTraTienAnSuccess = (payload: number[]): HoanTraTienAnDeleteSuccessAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteHoanTraTienAnError = (payload: string): HoanTraTienAnDeleteErrorAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_DELETE_ERROR,
        payload: payload
    }
}

export const changeHoanTraTienAnSelectionIds = (payload: number[]): HoanTraTienAnChangeSelectedIdsAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CHANGE_SELECTED_IDS,
        payload: payload
    }
}

export const changeHoanTraTienAnFilter = (filter: HoanTraTienAnSelectRequest): HoanTraTienAnChangeFilterAction => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CHANGE_FILTER,
        payload: filter
    }
}

export const showHoanTraTienAnImportModal = (): HoanTraTienAnShowImportModal => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_SHOW_IMPORT_MODEL
    }
}

export const closeHoanTraTienAnImportModal = (): HoanTraTienAnCloseImportModal => {
    return {
        type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CLOSE_IMPORT_MODEL
    }
}