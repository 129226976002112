import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { mucThuPhiApi } from "../../api/mucThuPhiApi";
import { IBaseResponeModel } from "../../models/response/base-response";
import { MucThuPhiLoadStartAction } from "../action-types/mucThuPhiActionTypes";
import { MucThuPhiNgsActionTypeIds, MucThuPhiNgsCreateSaleSetStart, MucThuPhiNgsSavingStart } from './../action-types/mucThuPhiNgsActionTypes';
import {
    loadMucThuPhiNgsError, loadMucThuPhiNgsSuccess, savingMucThuPhiNgsError, savingMucThuPhiNgsSuccess,
    createSaleSetSuccess, createSaleSetError
} from './../actions/mucThuPhiNgsActions';
export function* mucThuPhiNgsSagas(): any {
    yield takeEvery(MucThuPhiNgsActionTypeIds.LOAD_START, loadMucThuPhiStartWorker)
    yield takeEvery(MucThuPhiNgsActionTypeIds.SAVING_START, saveMucThuPhiNgsStartWorker)
    yield takeLatest(MucThuPhiNgsActionTypeIds.CREATE_SALE_SET_START, createSaleSetWorker)
}
function* createSaleSetWorker(data: MucThuPhiNgsCreateSaleSetStart) {
    const res: IBaseResponeModel = yield call(mucThuPhiApi.createSaleSet, data.payload)
    if (res.is_success) {
        yield put(createSaleSetSuccess())
    } else {
        yield put(createSaleSetError())
    }
}
function* saveMucThuPhiNgsStartWorker(data: MucThuPhiNgsSavingStart) {
    const res: IBaseResponeModel = yield call(mucThuPhiApi.savechanges, data.payload)
    if (res.is_success) {
        yield put(savingMucThuPhiNgsSuccess())
    } else {
        yield put(savingMucThuPhiNgsError(res.message || ""))
    }
}
function* loadMucThuPhiStartWorker(data: MucThuPhiLoadStartAction) {
    const res: IBaseResponeModel = yield call(mucThuPhiApi.select, data.payload)
    if (res.is_success) {
        yield put(loadMucThuPhiNgsSuccess(res.data))
    } else {
        yield put(loadMucThuPhiNgsError(res.message || ""))
    }
}
