import { sf_tbtp_auto_hachtoan_rq } from "../../models/request/tbtp/sf_tbtp_auto_hachtoan_rq"
import { sf_tbtp_pdf_create_rq } from "../../models/request/tbtp/sf_tbtp_pdf_create_rq"
import { sf_tbtp_select_rq } from "../../models/request/tbtp/sf_tbtp_select_rq"
import { sf_tbtp_send_rq } from "../../models/request/tbtp/sf_tbtp_send_rq"
import { sf_tbtp, sf_tbtp_viewmodel } from "../../models/response/tbtp/sf_tbtp"
export enum TBTPActionTypeIds {
    TBTP_LOAD_START = "TBTP_LOAD_START",
    TBTP_LOAD_SUCCESS = "TBTP_LOAD_SUCCESS",
    TBTP_LOAD_ERROR = "TBTP_LOAD_ERROR",

    TBTP_SHOW_DETAIL_MODAL = "TBTP_SHOW_DETAIL_MODAL",
    TBTP_CLOSE_DETAIL_MODAL = "TBTP_CLOSE_DETAIL_MODAL",

    TBTP_SHOW_DELETE_CONFIRM = "TBTP_SHOW_DELETE_CONFIRM",
    TBTP_CLOSE_DELETE_CONFIRM = "TBTP_CLOSE_DELETE_CONFIRM",

    TBTP_SAVE_START = "TBTP_SAVE_START",
    TBTP_SAVE_SUCCESS = "TBTP_SAVE_SUCCESS",
    TBTP_SAVE_ERROR = "TBTP_SAVE_ERROR",

    TBTP_DELETE_START = "TBTP_DELETE_START",
    TBTP_DELETE_SUCCESSS = "TBTP_DELETE_SUCCESSS",
    TBTP_DELETE_ERROR = "TBTP_DELETE_ERROR",

    TBTP_CHANGE_SELECTED_IDS = "TBTP_CHANGE_SELECTED_IDS",


    TBTP_NAMHOC_CHANGED = "TBTP_NAMHOC_CHANGED",
    TBTP_TRUONG_CHANGED = "TBTP_TRUONG_CHANGED",
    TBTP_KHOI_CHANGED = "TBTP_KHOI_CHANGED",
    TBTP_HE_CHANGED = "TBTP_HE_CHANGED",

    TBTP_SHOW_EDIT = "TBTP_SHOW_EDIT",
    TBTP_CLOSE_EDIT = "TBTP_CLOSE_EDIT",

    TBTP_SHOW_PDF_CONFIRM = "TBTP_SHOW_PDF_CONFIRM",
    TBTP_CLOSE_PDF_CONFIRM = "TBTP_CLOSE_PDF_CONFIRM",
    TBTP_CREATE_PDF_START = "TBTP_CREATE_PDF_START",
    TBTP_CREATE_PDF_SUCCESS = "TBTP_CREATE_PDF_SUCCESS",
    TBTP_CREATE_PDF_ERROR = "TBTP_CREATE_PDF_ERROR",


    TBTP_AUTO_HACHTOAN_SHOW_CONFIRM = "TBTP_AUTO_HACHTOAN_SHOW_CONFIRM",
    TBTP_AUTO_HACHTOAN_CLOSE_CONFIRM = "TBTP_AUTO_HACHTOAN_CLOSE_CONFIRM",
    TBTP_AUTO_HACHTOAN_START = "TBTP_AUTO_HACHTOAN_START",
    TBTP_AUTO_HACHTOAN_SUCCESS = "TBTP_AUTO_HACHTOAN_SUCCESS",
    TBTP_AUTO_HACHTOAN_ERRROR = "TBTP_AUTO_HACHTOAN_ERRROR",


    
    TBTP_SEND_SHOW_CONFIRM = "TBTP_SEND_CONFIRM",
    TBTP_SEND_CLOSE_CONFIRM = "TBTP_SEND_CLOSE_CONFIRM",
    TBTP_SEND_START = "TBTP_SEND_START",
    TBTP_SEND_SUCCESS = "TBTP_SEND_SUCCESS",
    TBTP_SEND_ERR = "TBTP_SEND_ERR"

}


export interface TBTPSendShowConfirm {
    type: TBTPActionTypeIds.TBTP_SEND_SHOW_CONFIRM,
    payload: number[]
}

export interface TBTPSendCloseConfirm {
    type: TBTPActionTypeIds.TBTP_SEND_CLOSE_CONFIRM,

}

export interface TBTPSendStart {
    type: TBTPActionTypeIds.TBTP_SEND_START,
    payload: sf_tbtp_send_rq

}


export interface TBTPSendSuccess {
    type: TBTPActionTypeIds.TBTP_SEND_SUCCESS,

}


export interface TBTPSendErr {
    type: TBTPActionTypeIds.TBTP_SEND_ERR,
    payload: string

}


export interface TBTPAutoHachToanShowConfirm {
    type: TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_SHOW_CONFIRM,
    payload: number[]
}

export interface TBTPAutoHachToanCloseConfirm {
    type: TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_CLOSE_CONFIRM,

}

export interface TBTPAutoHachToanStart {
    type: TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_START,
    payload: sf_tbtp_auto_hachtoan_rq

}


export interface TBTPAutoHachToanSuccess {
    type: TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_SUCCESS,

}


export interface TBTPAutoHachToanErrror {
    type: TBTPActionTypeIds.TBTP_AUTO_HACHTOAN_ERRROR,
    payload: string

}


export interface TBTPLoadStartAction {
    type: TBTPActionTypeIds.TBTP_LOAD_START,
    payload: sf_tbtp_select_rq
}
export interface TBTPLoadSuccessAction {
    type: TBTPActionTypeIds.TBTP_LOAD_SUCCESS,
    payload: sf_tbtp_viewmodel[]
}
export interface TBTPLoadErrorAction {
    type: TBTPActionTypeIds.TBTP_LOAD_ERROR,
    payload: string
}

export interface TBTPShowDetailModalAction {
    type: TBTPActionTypeIds.TBTP_SHOW_DETAIL_MODAL
}
export interface TBTPCloseDetailModalAction {
    type: TBTPActionTypeIds.TBTP_CLOSE_DETAIL_MODAL,
}
export interface TBTPShowDeleteConfirmAction {
    type: TBTPActionTypeIds.TBTP_SHOW_DELETE_CONFIRM,
}
export interface TBTPCloseDeleteConfirmAction {
    type: TBTPActionTypeIds.TBTP_CLOSE_DELETE_CONFIRM,
}

export interface TBTPSaveStartAction {
    type: TBTPActionTypeIds.TBTP_SAVE_START,
    payload: sf_tbtp
}
export interface TBTPSaveSuccessAction {
    type: TBTPActionTypeIds.TBTP_SAVE_SUCCESS,
    payload: sf_tbtp
}
export interface TBTPSaveErrorAction {
    type: TBTPActionTypeIds.TBTP_SAVE_ERROR,
    payload: string
}
export interface TBTPDeleteStartAction {
    type: TBTPActionTypeIds.TBTP_DELETE_START,
    payload: number[]
}
export interface TBTPDeleteSuccessAction {
    type: TBTPActionTypeIds.TBTP_DELETE_SUCCESSS,
    payload: number[]
}
export interface TBTPDeleteErrorAction {
    type: TBTPActionTypeIds.TBTP_DELETE_ERROR,
    payload: string
}

export interface TBTPChangeSelectedIdsAction {
    type: TBTPActionTypeIds.TBTP_CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface TBTPNamHocChangedAction {
    type: TBTPActionTypeIds.TBTP_NAMHOC_CHANGED,
    payload: string
}
export interface TBTPTruongChangedAction {
    type: TBTPActionTypeIds.TBTP_TRUONG_CHANGED,
    payload: number
}
export interface TBTPKhoiChangedAction {
    type: TBTPActionTypeIds.TBTP_KHOI_CHANGED,
    payload: number
}
export interface TBTPHeChangedAction {
    type: TBTPActionTypeIds.TBTP_HE_CHANGED,
    payload: number
}

export interface TBTPShowEditAction {
    type: TBTPActionTypeIds.TBTP_SHOW_EDIT,
    payload: sf_tbtp_viewmodel
}

export interface TBTPCloseEditAction {
    type: TBTPActionTypeIds.TBTP_CLOSE_EDIT,
}

export interface TBTPShowPdfConfirm {
    type: TBTPActionTypeIds.TBTP_SHOW_PDF_CONFIRM,
    payload: number[]
}

export interface TBTPClosePdfConfirm {
    type: TBTPActionTypeIds.TBTP_CLOSE_PDF_CONFIRM,

}

export interface TBTPCreatePdfStart {
    type: TBTPActionTypeIds.TBTP_CREATE_PDF_START,
    payload: sf_tbtp_pdf_create_rq

}


export interface TBTPCreatePdfSuccess {
    type: TBTPActionTypeIds.TBTP_CREATE_PDF_SUCCESS,

}


export interface TBTPCreatePdfError {
    type: TBTPActionTypeIds.TBTP_CREATE_PDF_ERROR,
    payload: string

}

export type TBTPActionTypes =
    TBTPLoadStartAction | TBTPLoadSuccessAction | TBTPLoadErrorAction |
    TBTPShowDetailModalAction | TBTPShowDeleteConfirmAction |
    TBTPCloseDetailModalAction | TBTPCloseDeleteConfirmAction |
    TBTPSaveStartAction | TBTPSaveSuccessAction | TBTPSaveErrorAction |
    TBTPDeleteStartAction | TBTPDeleteSuccessAction | TBTPDeleteErrorAction |
    TBTPChangeSelectedIdsAction |
    TBTPTruongChangedAction | TBTPKhoiChangedAction | TBTPHeChangedAction | TBTPNamHocChangedAction |
    TBTPShowEditAction | TBTPCloseEditAction |
    TBTPShowPdfConfirm | TBTPClosePdfConfirm | TBTPCreatePdfStart | TBTPCreatePdfSuccess | TBTPCreatePdfError |
    TBTPAutoHachToanShowConfirm | TBTPAutoHachToanCloseConfirm |
    TBTPAutoHachToanStart | TBTPAutoHachToanSuccess | TBTPAutoHachToanErrror |
    TBTPSendShowConfirm | TBTPSendCloseConfirm |
    TBTPSendStart |TBTPSendSuccess | TBTPSendErr
