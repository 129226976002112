import { call, put, takeEvery } from "redux-saga/effects"
import { thuPhiApi } from "../../api/thuPhiApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { ThuPhiActionTypeIds, ThuPhiCreateFromKhoanNopStart, ThuPhiCreatFromTBTPStart, ThuPhiMucPhaiThuLoadStart } from "../action-types/thuPhiActionTypes"
import {
    loadThuPhiMucPhaiThuSuccess, loadThuPhiMucPhaiThuError, createThuPhiFromTBTPSuccess, createThuPhiFromTBTPErr, createThuPhiFromKhoanNopSuccess, createThuPhiFromKhoanNopErr
} from "../actions/thuPhiActions"
export function* thuPhiSagas(): any {
    yield takeEvery(ThuPhiActionTypeIds.THUPHI_MUCPHAITHU_LOAD_START, loadStart)
    yield takeEvery(ThuPhiActionTypeIds.THUPHI_CREATE_FROM_TBTP_START, createFromTBTPStart)
    yield takeEvery(ThuPhiActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_START, createFromKhoanNopStart)
}

function* loadStart(data: ThuPhiMucPhaiThuLoadStart) {
    const res: IBaseResponeModel = yield call(thuPhiApi.select_by_hocsinh, data.payload)
    if (res.is_success) {
        yield put(loadThuPhiMucPhaiThuSuccess(res.data))
    } else {
        yield put(loadThuPhiMucPhaiThuError(res.message || "Không tải được dữ liệu"))
    }
}

function* createFromTBTPStart(data: ThuPhiCreatFromTBTPStart) {
    const res: IBaseResponeModel = yield call(thuPhiApi.insert_from_bom, data.payload)
    if (res.is_success) {
        yield put(createThuPhiFromTBTPSuccess())
    } else {
        yield put(createThuPhiFromTBTPErr(res.message || "Không tải được dữ liệu"))
    }
}

function* createFromKhoanNopStart(data: ThuPhiCreateFromKhoanNopStart) {
    const res: IBaseResponeModel = yield call(thuPhiApi.insert_from_khoannop, data.payload)
    if (res.is_success) {
        yield put(createThuPhiFromKhoanNopSuccess())
    } else {
        yield put(createThuPhiFromKhoanNopErr(res.message || "Không tải được dữ liệu"))
    }
}