import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { sf_dexuatmiengiam, sf_dexuatmiengiam_viewmodel } from "../../models/response/gan-mien-giam/sf_dexuatmiengiam";
import { sf_dexuatmiengiam_changed_item_res } from "../../models/response/gan-mien-giam/sf_dexuatmiengiam_changed_res";
import { GanMienGiamActionTypeIds, GanMienGiamActionTypes } from "../action-types/ganMienGiamActionTypes";
import { GanMienGiamPageState } from "../page-state-model/ganMienGiamPageState";
const initialState: GanMienGiamPageState = {
    status: PageBaseStatus.is_completed,
    filter: {
        nam_hoc: localStorageHelper.getNamHoc(),
        dm_he_id: localStorageHelper.getDmHeId(),
        dm_khoi_id: localStorageHelper.getDmKhoiId(),
        dm_truong_id: localStorageHelper.getDmTruongId()
    },
    filterStudent: undefined,
    is_show_filter_student_modal: false,
    sf_dexuatmiengiams: [],
    sf_hinhthucmiengiams: [],
    ts_hocsinhs: []
}

export const ganMienGiamReducer = (state: GanMienGiamPageState = initialState, action: GanMienGiamActionTypes): GanMienGiamPageState => {
    switch (action.type) {
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_FILTER_STUDENT:
            return {
                ...state,
                filterStudent: action.payload
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_SHOW_FILTER_STUDENT_MODAL:
            return {
                ...state,
                is_show_filter_student_modal: true
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CLOSE_FILTER_STUDENT_MODAL:
            return {
                ...state,
                is_show_filter_student_modal: false
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading,
                filter: action.payload
            }

        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_dexuatmiengiams: action.payload
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_ERROR:
            showNotify({ message: "Có lỗi khi tải dữ liệu" + action.payload, type: 'error' })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_dexuatmiengiams: []
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HINHTHUCMIENGIAM_SUCCESS:
            return {
                ...state,
                sf_hinhthucmiengiams: action.payload
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HOCSINH_SUCCESS:
            return {
                ...state,
                ts_hocsinhs: action.payload
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_SUCCESS:

            let new_sf_dexuatmiengiams = [...state.sf_dexuatmiengiams];
            action.payload.items.forEach((item: sf_dexuatmiengiam_changed_item_res) => {
                if (item.status === "added") { new_sf_dexuatmiengiams.push(item.sf_dexuatmiengiam) }
                if (item.status === "removed") {
                    new_sf_dexuatmiengiams = new_sf_dexuatmiengiams.filter(x => x.id !== item.sf_dexuatmiengiam.id)
                }
            })
            return {
                ...state,
                sf_dexuatmiengiams: new_sf_dexuatmiengiams
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_START:
            return {
                ...state,
                is_show_approve_confirm: true,
                approve_request: action.payload
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_CANCEL:
            return {
                ...state,
                is_show_approve_confirm: false,
                approve_request: undefined
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_SUBMIT:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_SUCCESS:
            showNotify({ message: "Cập nhật thành công", type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_approve_confirm: false,
                approve_request: undefined
            }
        case GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_ERR:
            showNotify({ message: "Có lỗi " + action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                approve_request: undefined
            }
        default:
            return state;
    }
}