import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { BomChiTietActionTypeIds, BomChiTietActionTypes } from "../action-types/bomChiTietActionTypes";
import { BomChiTietPageState } from "../page-state-model/bomChiTietPageState";

const initialState: BomChiTietPageState = {
    sf_bom_chitiet_viewmodels: [],
    sf_bom_hinhthucnopbom_viewmodels: [],
    status: PageBaseStatus.is_completed,
    sf_khoannop_selected_ids: []
}

export const bomChiTietReducer = (state: BomChiTietPageState = initialState, action: BomChiTietActionTypes): BomChiTietPageState => {
    switch (action.type) {
        case BomChiTietActionTypeIds.BOMCHITIET_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case BomChiTietActionTypeIds.BOMCHITIET_LOAD_ERR:
            showNotify({ message: `Có lỗi: ${action.payload}`, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }
        case BomChiTietActionTypeIds.BOMCHITIET_LOAD_SUCCESS:

            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_bom_chitiet_viewmodels: action.payload.sf_bom_chitiet_viewmodels,
                sf_bom_hinhthucnopbom_viewmodels: action.payload.sf_bom_hinhthucnopbom_viewmodels
            }
        case BomChiTietActionTypeIds.BOMCHITIET_SHOW_KHOANNOP_MODAL:
            return {
                ...state,
                is_show_add_khoannop_modal: true,
                sf_bom_hinhthucnopbom_viewmodel_selected: action.payload
            }
        case BomChiTietActionTypeIds.BOMCHITIET_CLOSE_KHOANNOP_MODAL:
            return {
                ...state,
                is_show_add_khoannop_modal: false
            }
        case BomChiTietActionTypeIds.BOMCHITIET_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_khoannop_selected_ids: action.payload
            }
        case BomChiTietActionTypeIds.BOMCHITIET_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        case BomChiTietActionTypeIds.BOMCHITIET_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: false
            }
        case BomChiTietActionTypeIds.BOMCHITIET_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case BomChiTietActionTypeIds.BOMCHITIET_DELETE_SUCCESS:
            showNotify({ type: "success", message: "Đã xóa thành công" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_delete_confirm:false
            }
        case BomChiTietActionTypeIds.BOMCHITIET_DELETE_ERRROR:
            showNotify({ type: "warning", message: action.payload })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }
        default:
            return state;
    }
}