import { call, put, takeEvery } from "redux-saga/effects";
import { saleSetApi } from '../../api/saleSetApi';
import { IBaseResponeModel } from "../../models/response/base-response";
import { eHocSinhProfileActionTypeIds, IHocSinhProfileSelectSaleSetStart } from '../action-types/hocSinhProfileActionTypes';
import { hocSinhProfileActions } from '../actions/hocSinhProfileActions';
import { hocSinhApi } from './../../api/hocSinhApi';
import { thuPhiApi } from './../../api/thuPhiApi';
import { IHocSinhProfileCreateThuPhiFromKhoanNopStart, IHocSinhProfileSelectChungTuStart, IHocSinhProfileSelectHocSinhStart, IHocSinhProfileCreateThuPhiFromTBPTStart } from './../action-types/hocSinhProfileActionTypes';
export function* hocSinhProfileSaga(): any {
    yield takeEvery(eHocSinhProfileActionTypeIds.SELECT_SALESET_START, loadSaleSetStart)
    yield takeEvery(eHocSinhProfileActionTypeIds.SELECT_HOCSINH_START, loadHocSinhStart)
    yield takeEvery(eHocSinhProfileActionTypeIds.SELECT_CHUNGTU_START, loadChungTuStart)
    yield takeEvery(eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_TBTP_START, createFromTBTPStart)
    yield takeEvery(eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_START, createFromKhoanNopStart)
}

function* loadSaleSetStart(data: IHocSinhProfileSelectSaleSetStart) {
    const res: IBaseResponeModel = yield call(saleSetApi.selectByStudent, data.payload)
    if (res.is_success) {
        yield put(hocSinhProfileActions.selectSaleSetSuccess(res.data))
    } else {
        yield put(hocSinhProfileActions.selectSaleSetErr(res.message || "Không tải được dữ liệu"))
    }
}

function* loadHocSinhStart(data: IHocSinhProfileSelectHocSinhStart) {
    const res: IBaseResponeModel = yield call(hocSinhApi.selectById, data.payload)
    if (res.is_success) {
        yield put(hocSinhProfileActions.selectHocSinhSuccess(res.data))
    } else {
        yield put(hocSinhProfileActions.selectHocSinhError(res.message || "Không tải được dữ liệu"))
    }
}
function* loadChungTuStart(data: IHocSinhProfileSelectChungTuStart) {
    const res: IBaseResponeModel = yield call(thuPhiApi.select_by_hocsinh, data.payload)
    if (res.is_success) {
        yield put(hocSinhProfileActions.selectChungTuSuccess(res.data))
    } else {
        yield put(hocSinhProfileActions.selectChungTuError(res.message || "Không tải được dữ liệu"))
    }
}

function* createFromTBTPStart(data: IHocSinhProfileCreateThuPhiFromTBPTStart) {
    const res: IBaseResponeModel = yield call(thuPhiApi.insert_from_bom, data.payload)
    if (res.is_success) {
        yield put(hocSinhProfileActions.createThuPhiFromTBTPSuccess())
    } else {
        yield put(hocSinhProfileActions.createThuPhiFromTBTPError(res.message || "Không tải được dữ liệu"))
    }
}

function* createFromKhoanNopStart(data: IHocSinhProfileCreateThuPhiFromKhoanNopStart) {
    const res: IBaseResponeModel = yield call(thuPhiApi.insert_from_khoannop, data.payload)
    if (res.is_success) {
        yield put(hocSinhProfileActions.createThuPhiFromKhoanNopSuccess())
    } else {
        yield put(hocSinhProfileActions.createThuPhiFromKhoanNopError(res.message || "Không tải được dữ liệu"))
    }
}
