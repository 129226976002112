
import { sf_bom_chitiet_remove_khoannop_rq } from "../models/request/bom/sf_bom_chitiet_remove_khoannop_rq";
import { apiClient } from "./apiClient";

export const SF_BOM_CHITIET_SELECT_API = "sf_bom_chitiet/select_by_bom";
export const SF_BOM_CHITIET_INSERT_API = "sf_bom_chitiet/insert";
export const SF_BOM_CHITIET_ADDKHOANNOP_API = "sf_bom_chitiet/add-khoan-nop";
export const SF_BOM_CHITIET_SELECT_MUCTHUPHI = "sf_bom_chitiet/select_mucthuphi"
export const SF_BOM_CHITIET_DELETE_KHOANNOP = "sf_bom_chitiet/delete"


export const bomChiTietApi = {
    select: (sf_bom_id: number) => {
        return apiClient.post(`${SF_BOM_CHITIET_SELECT_API}?sf_bom_id=${sf_bom_id}`)
    },
    select_mucthuphi: (sf_bom_id: number) => {
        return apiClient.post(`${SF_BOM_CHITIET_SELECT_MUCTHUPHI}?sf_bom_id=${sf_bom_id}`)
    },
    insert: (data: any) => {
        return apiClient.post(SF_BOM_CHITIET_INSERT_API, data)
    },
    addKhoanNop: (data: any) => {
        return apiClient.post(SF_BOM_CHITIET_ADDKHOANNOP_API, data)
    },
    delete: (data: sf_bom_chitiet_remove_khoannop_rq) => {
        return apiClient.post(SF_BOM_CHITIET_DELETE_KHOANNOP, data)
    },

}