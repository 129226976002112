import { ts_hocsinh_viewmodel } from './../../models/response/hocsinh/ts_hocsinh';
import { IActionBase } from './IActionBase';
import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter";
import { sf_saleset_register_dichvu } from './../../models/response/sale-set-dichvu/sf_saleset_register_dichvu';

export enum eSaleSetDichVuTypeIds {
    LOAD_START = "SALESETREGISTERDICHVU_LOAD_START",
    LOAD_SUCCESS = " SALESETREGISTERDICHVU_LOAD_SUCCESS",
    LOAD_ERRROR = "SALESETREGISTERDICHVU_LOAD_ERRROR",

    LOAD_HOCSINH_START = "SALESETREGISTERDICHVU_LOAD_HOCSINH_START",
    LOAD_HOCSINH_SUCCESS = "SALESETREGISTERDICHVU_LOAD_HOCSINH_SUCCESS",
    LOAD_HOCSINH_ERROR = "SALESETREGISTERDICHVU_LOAD_HOCSINH_ERROR",

    CHANGE_FILTER = "SALESETREGISTERDICHVU_CHANGE_FILTER"

}
export interface ISaleSetDichVuLoadStart {
    type: eSaleSetDichVuTypeIds.LOAD_START,
    payload: NamHocTruongKhoiHeFilter
}
export interface ISaleSetDichVuLoadSuccess {
    type: eSaleSetDichVuTypeIds.LOAD_SUCCESS,
    payload: sf_saleset_register_dichvu[]
}
export interface ISaleSetDichVuLoadError {
    type: eSaleSetDichVuTypeIds.LOAD_ERRROR,
    payload: string
}

export interface ISaleSetDichVuLoadHocSinhStart extends IActionBase<eSaleSetDichVuTypeIds.LOAD_HOCSINH_START, NamHocTruongKhoiHeFilter> { }
export interface ISaleSetDichVuLoadHocSinhSuccess extends IActionBase<eSaleSetDichVuTypeIds.LOAD_HOCSINH_SUCCESS, ts_hocsinh_viewmodel[]> { }
export interface ISaleSetDichVuLoadHocSinhError extends IActionBase<eSaleSetDichVuTypeIds.LOAD_HOCSINH_ERROR, string> { }
export interface ISaleSetDichVuChangeFilter extends IActionBase<eSaleSetDichVuTypeIds.CHANGE_FILTER, NamHocTruongKhoiHeFilter> { }

export type SaleSetDichVuActionTypes = ISaleSetDichVuLoadStart | ISaleSetDichVuLoadSuccess | ISaleSetDichVuLoadError |
    ISaleSetDichVuLoadHocSinhStart | ISaleSetDichVuLoadHocSinhSuccess | ISaleSetDichVuLoadHocSinhError | ISaleSetDichVuChangeFilter