// console.log(process.env)

interface IAppInfo {
    baseApiURL: string
    homeURL: string,
    subSytemId: number,
    versionDataGridStorageKey?: string
}

 const appInfo: IAppInfo = {
     baseApiURL:process.env.REACT_APP_API_BASE_URL?.toString() || "",// 'https://phenikaa-finance.3s.edu.vn/api',
     homeURL: process.env.REACT_APP_PORTAL_URL?.toString() || "", //'https://portal.3s.edu.vn',
     subSytemId: 2,
     versionDataGridStorageKey: process.env.DATA_GRID_STOREGE_VERSION?.toString() || ""
 };
export { appInfo }

