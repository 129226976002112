import { sf_khoannop_group } from "../../models/response/category/sf_khoannop_group"
export enum KhoanNopGroupActionTypeIds {
    KHOANNOPGROUP_LOAD_START = "KHOANNOPGROUP_LOAD_START",
    KHOANNOPGROUP_LOAD_SUCCESS = "KHOANNOPGROUP_LOAD_SUCCESS",
    KHOANNOPGROUP_LOAD_ERROR = "KHOANNOPGROUP_LOAD_ERROR",

    KHOANOP_SHOW_DETAIL_MODAL = "KHOANOP_SHOW_DETAIL_MODAL",
    KHOANNOPGROUP_SHOW_DELETE_CONFIRM = "KHOANNOPGROUP_SHOW_DELETE_CONFIRM",

    KHOANNOPGROUP_SAVE_START = "KHOANNOPGROUP_SAVE_START",
    KHOANNOPGROUP_SAVE_SUCCESS = "KHOANNOPGROUP_SAVE_SUCCESS",
    KHOANNOPGROUP_SAVE_ERROR = "KHOANNOPGROUP_SAVE_ERROR",

    KHOANNOPGROUP_DELETE_START = "KHOANNOPGROUP_DELETE_START",
    KHOANNOPGROUP_DELETE_SUCCESSS = "KHOANNOPGROUP_DELETE_SUCCESSS",
    KHOANNOPGROUP_DELETE_ERROR = "KHOANNOPGROUP_DELETE_ERROR",

    KHOANNOPGROUP_CHANGE_SELECTED_IDS = "KHOANNOPGROUP_CHANGE_SELECTED_IDS"

}

export interface KhoanNopGroupLoadStartAction {
    type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_LOAD_START
}
export interface KhoanNopGroupLoadSuccessAction {
    type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_LOAD_SUCCESS,
    payload: sf_khoannop_group[]
}
export interface KhoanNopGroupLoadErrorAction {
    type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_LOAD_ERROR,
    payload: string
}

export interface KhoanNopGroupShowDetailModalAction {
    type: KhoanNopGroupActionTypeIds.KHOANOP_SHOW_DETAIL_MODAL,
    payload: boolean

}
export interface KhoanNopGroupShowDeleteConfirmAction {
    type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SHOW_DELETE_CONFIRM,
    payload: boolean
}
export interface KhoanNopGroupSaveStartAction {
    type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SAVE_START,
    payload: sf_khoannop_group
}
export interface KhoanNopGroupSaveSuccessAction {
    type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SAVE_SUCCESS,
    payload: sf_khoannop_group
}
export interface KhoanNopGroupSaveErrorAction {
    type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_SAVE_ERROR,
    payload: string
}
export interface KhoanNopGroupDeleteStartAction {
    type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_DELETE_START,
    payload: number[]
}
export interface KhoanNopGroupDeleteSuccessAction {
    type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_DELETE_SUCCESSS,
    payload: number[]
}
export interface KhoanNopGroupDeleteErrorAction {
    type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_DELETE_ERROR,
    payload: string
}

export interface KhoanNopGroupChangeSelectedIdsAction {
    type: KhoanNopGroupActionTypeIds.KHOANNOPGROUP_CHANGE_SELECTED_IDS,
    payload: number[]
}
export type KhoanNopGroupActionTypes =
    KhoanNopGroupLoadStartAction | KhoanNopGroupLoadSuccessAction | KhoanNopGroupLoadErrorAction |
    KhoanNopGroupShowDetailModalAction | KhoanNopGroupShowDeleteConfirmAction |
    KhoanNopGroupSaveStartAction | KhoanNopGroupSaveSuccessAction | KhoanNopGroupSaveErrorAction |
    KhoanNopGroupDeleteStartAction | KhoanNopGroupDeleteSuccessAction | KhoanNopGroupDeleteErrorAction |
    KhoanNopGroupChangeSelectedIdsAction