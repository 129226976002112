import { sf_hinhthucmiengiam } from "../../models/response/category/sf_hinhthucmiengiam"
import { sf_hinhthucmiengiam_hinhthuctinh } from "../../models/response/category/sf_hinhthucmiengiam_hinhthuctinh"
import {
    HinhThucMienGiamActionTypeIds,
    HinhThucMienGiamLoadStartAction, HinhThucMienGiamLoadSuccessAction, HinhThucMienGiamLoadErrorAction, HinhThucMienGiamShowDetailModalAction,
    HinhThucMienGiamSaveStartAction, HinhThucMienGiamSaveSuccessAction, HinhThucMienGiamSaveErrorAction, HinhThucMienGiamDeleteStartAction, HinhThucMienGiamDeleteSuccessAction, HinhThucMienGiamDeleteErrorAction, HinhThucMienGiamChangeSelectedIdsAction, HinhThucMienGiamShowDeleteConfirmAction, HinhThucMienGiamCloseDetailModalAction, HinhThucMienGiamCloseDeleteConfirmAction, HinhThucMienGiamHinhThucTinhLoadStartAction, HinhThucMienGiamHinhThucTinhLoadSuccessAction, HinhThucMienGiamChangeNamHocAction, HinhThucMienGiamShowItemsModalAction, HinhThucMienGiamCloseItemModalAction, HinhThucMienGiamShowCopyModalAction, HinhThucMienGiamCloseCopyModalAction
} from "../action-types/hinhThucMienGiamActionTypes"

export const loadHinhThucMienGiamStart = (nam_hoc: string): HinhThucMienGiamLoadStartAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_LOAD_START,
        payload: nam_hoc
    }
}

export const loadHinhThucMienGiamSuccess = (payload: sf_hinhthucmiengiam[]): HinhThucMienGiamLoadSuccessAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_LOAD_SUCCESS,
        payload: payload
    }
}
export const loadHinhThucMienGiamError = (payload: string): HinhThucMienGiamLoadErrorAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_LOAD_ERROR,
        payload: payload
    }
}

export const showHinhThucMienGiamDetailModal = (payload?: sf_hinhthucmiengiam): HinhThucMienGiamShowDetailModalAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_DETAIL_MODAL,
        payload: payload
    }
}
export const closeHinhThucMienGiamDetailModal = (): HinhThucMienGiamCloseDetailModalAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_DETAIL_MODAL,
    }
}

export const showHinhThucMienGiamDeleteConfirm = (): HinhThucMienGiamShowDeleteConfirmAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_DELETE_CONFIRM,
    }
}
export const closeHinhThucMienGiamDeleteConfirm = (): HinhThucMienGiamCloseDeleteConfirmAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_DELETE_CONFIRM,
    }
}

export const saveHinhThucMienGiamStart = (payload: sf_hinhthucmiengiam): HinhThucMienGiamSaveStartAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SAVE_START,
        payload: payload
    }
}

export const saveHinhThucMienGiamSuccess = (payload: sf_hinhthucmiengiam): HinhThucMienGiamSaveSuccessAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SAVE_SUCCESS,
        payload: payload
    }
}
export const saveHinhThucMienGiamError = (payload: string): HinhThucMienGiamSaveErrorAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SAVE_ERROR,
        payload: payload
    }
}

export const deleteHinhThucMienGiamStart = (payload: number[]): HinhThucMienGiamDeleteStartAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_DELETE_START,
        payload: payload
    }
}

export const deleteHinhThucMienGiamSuccess = (payload: number[]): HinhThucMienGiamDeleteSuccessAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_DELETE_SUCCESSS,
        payload: payload
    }
}
export const deleteHinhThucMienGiamError = (payload: string): HinhThucMienGiamDeleteErrorAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_DELETE_ERROR,
        payload: payload
    }
}

export const changeHinhThucMienGiamSelectionIds = (payload: number[]): HinhThucMienGiamChangeSelectedIdsAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CHANGE_SELECTED_IDS,
        payload: payload
    }
}


export const loadHinhThucMienGiamHinhThucTinhStartAction = (): HinhThucMienGiamHinhThucTinhLoadStartAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_HINHTHUCTINH_LOAD_START,

    }
}


export const loadHinhThucMienGiamHinhThucTinhSuccessAction = (payload: sf_hinhthucmiengiam_hinhthuctinh[]): HinhThucMienGiamHinhThucTinhLoadSuccessAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_HINHTHUCTINH_LOAD_SUCCESS,
        payload: payload

    }
}

export const changeHinhThucMienGiamNamHocAction = (payload: string): HinhThucMienGiamChangeNamHocAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CHANGE_NAMHOC,
        payload: payload
    }
}

export const showHinhThucMienGiamItemsModalAction = (payload: sf_hinhthucmiengiam): HinhThucMienGiamShowItemsModalAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_ITEMS_MODAL,
        payload: payload
    }
}

export const closeHinhThucMienGiamItemsModalAction = (): HinhThucMienGiamCloseItemModalAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_ITEMS_MODAL
    }
}

export const showHinhThucMienGiamCopyModalAction = (): HinhThucMienGiamShowCopyModalAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SHOW_COPY_MODAL,

    }
}

export const closeHinhThucMienGiamCopyModalAction = (): HinhThucMienGiamCloseCopyModalAction => {
    return {
        type: HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_CLOSE_COPY_MODAL
    }
}
