import { dm_he } from "../../models/response/category/dm_he"
import { dm_khoi } from "../../models/response/category/dm_khoi"
import { dm_namhoc } from "../../models/response/category/dm_namhoc"
import { dm_truong } from "../../models/response/category/dm_truong"
import { dm_truong_khoi_he } from "../../models/response/category/dm_truong_khoi_he"
import { sf_hinhthucnopbom } from "../../models/response/category/sf_hinhthucnopbom"
import { dm_truong_treeview } from "../page-state-model/categorySourceState"

export enum CategorySourceActionTypeIds {
    DM_NAMHOC_LOAD_START = "DM_NAMHOC_LOAD_START",
    DM_NAMHOC_LOAD_SUCESS = "DM_NAMHOC_LOAD_SUCESS",

    DM_TRUONG_LOAD_START = "DM_TRUONG_LOAD_START",
    DM_TRUONG_LOAD_SUCCESS = "DM_TRUONG_LOAD_SUCCESS",

    DM_KHOI_LOAD_START = "DM_KHOI_LOAD_START",
    DM_KHOI_LOAD_SUCCESS = "DM_KHOI_LOAD_SUCCESS",

    DM_HE_LOAD_START = "DM_HE_LOAD_START",
    DM_HE_LOAD_SUCCESS = "DM_HE_LOAD_SUCCESS",

    DM_TRUONG_TREEVIEW_LOAD_START = "DM_TRUONG_TREEVIEW_LOAD_START",
    DM_TRUONG_TREEVIEW_LOAD_SUCCESS = "DM_TRUONG_TREEVIEW_LOAD_SUCCESS",

    DM_TRUONG_KHOI_HE_LOAD_START = "DM_TRUONG_KHOI_HE_LOAD_START",
    DM_TRUONG_KHOI_HE_LOAD_SUCESS = "DM_TRUONG_KHOI_HE_LOAD_SUCESS",

    SF_HINHTHUCNOPBOM_LOAD_START="SF_HINHTHUCNOPBOM_LOAD_START",
    SF_HINHTHUCNOPBOM_LOAD_SUCCESS="SF_HINHTHUCNOPBOM_LOAD_SUCCESS"

}

export interface DmNamHocLoadStartAction {
    type: CategorySourceActionTypeIds.DM_NAMHOC_LOAD_START
}
export interface DmNamHocLoadSuccesAction {
    type: CategorySourceActionTypeIds.DM_NAMHOC_LOAD_SUCESS,
    payload: dm_namhoc[]
}

export interface DmTruongLoadStartAction {
    type: CategorySourceActionTypeIds.DM_TRUONG_LOAD_START
}
export interface DmTruongLoadSuccessAction {
    type: CategorySourceActionTypeIds.DM_TRUONG_LOAD_SUCCESS,
    payload: dm_truong[]
}
export interface DmKhoiLoadStartAction {
    type: CategorySourceActionTypeIds.DM_KHOI_LOAD_START
}
export interface DmKhoiLoadSuccessAction {
    type: CategorySourceActionTypeIds.DM_KHOI_LOAD_SUCCESS,
    payload: dm_khoi[]
}
export interface DmHeLoadStartAction {
    type: CategorySourceActionTypeIds.DM_HE_LOAD_START
}
export interface DmHeLoadSuccessAction {
    type: CategorySourceActionTypeIds.DM_HE_LOAD_SUCCESS,
    payload: dm_he[]
}
export interface DmTruongTreeViewLoadStartAction {
    type: CategorySourceActionTypeIds.DM_TRUONG_TREEVIEW_LOAD_START
}
export interface DmTruongTreeViewLoadSuccessAction {
    type: CategorySourceActionTypeIds.DM_TRUONG_TREEVIEW_LOAD_SUCCESS,
    payload: dm_truong_treeview[]
}

export interface DmTruongKhoiHeLoadStartAction {
    type: CategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_START
}
export interface DmTruongKhoiHeLoadSucessAction {
    type: CategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_SUCESS,
    payload: dm_truong_khoi_he[]
}



export interface SfHinhThucNopBomLoadStartAction {
    type: CategorySourceActionTypeIds.SF_HINHTHUCNOPBOM_LOAD_START
}
export interface SfHinhThucNopBomLoadSuccessAction {
    type: CategorySourceActionTypeIds.SF_HINHTHUCNOPBOM_LOAD_SUCCESS,
    payload: sf_hinhthucnopbom[]
}


export type CategorySourceActionTypes = DmNamHocLoadStartAction | DmNamHocLoadSuccesAction |
    DmTruongLoadStartAction | DmTruongLoadSuccessAction |
    DmKhoiLoadStartAction | DmKhoiLoadSuccessAction |
    DmHeLoadStartAction | DmHeLoadSuccessAction |
    DmTruongTreeViewLoadStartAction | DmTruongTreeViewLoadSuccessAction|
    DmTruongKhoiHeLoadStartAction | DmTruongKhoiHeLoadSucessAction |
    SfHinhThucNopBomLoadStartAction | SfHinhThucNopBomLoadSuccessAction