import { call, put, takeEvery } from "redux-saga/effects"
import { hinhThucMienGiamApi } from "../../api/hinhThucMienGiamApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { HinhThucMienGiamActionTypeIds, HinhThucMienGiamDeleteStartAction, HinhThucMienGiamHinhThucTinhLoadStartAction, HinhThucMienGiamLoadStartAction, HinhThucMienGiamSaveStartAction } from "../action-types/hinhThucMienGiamActionTypes"
import {
    loadHinhThucMienGiamError, loadHinhThucMienGiamSuccess, saveHinhThucMienGiamError, saveHinhThucMienGiamSuccess,
    deleteHinhThucMienGiamSuccess, deleteHinhThucMienGiamError, loadHinhThucMienGiamHinhThucTinhSuccessAction
} from "../actions/hinhThucMienGiamActions"
export function* hinhThucMienGiamSaga(): any {
    yield takeEvery(HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_LOAD_START, loadHinhThucMienGiamStartWorker)
    yield takeEvery(HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_SAVE_START, saveHinhThucMienGiamStartWorker)
    yield takeEvery(HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_DELETE_START, deleteHinhThucMienGiamStartWorker)
    yield takeEvery(HinhThucMienGiamActionTypeIds.HINHTHUCMIENGIAM_HINHTHUCTINH_LOAD_START, loadHinhThucMienGiamHinhThucTinhWorker)
}
function* loadHinhThucMienGiamStartWorker(data:HinhThucMienGiamLoadStartAction) {
    const res: IBaseResponeModel = yield call(hinhThucMienGiamApi.select,data.payload)
    if (res.is_success) {
        yield put(loadHinhThucMienGiamSuccess(res.data))
    } else {
        yield put(loadHinhThucMienGiamError(res.message || ""))
    }
}
function* saveHinhThucMienGiamStartWorker(data: HinhThucMienGiamSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(hinhThucMienGiamApi.update, data.payload)
    } else {
        res = yield call(hinhThucMienGiamApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveHinhThucMienGiamSuccess(data.payload))
    } else {
        yield put(saveHinhThucMienGiamError(res.message || ""))
    }
}
function* deleteHinhThucMienGiamStartWorker(data: HinhThucMienGiamDeleteStartAction) {
    const res: IBaseResponeModel = yield call(hinhThucMienGiamApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteHinhThucMienGiamSuccess(data.payload))
    } else {
        yield put(deleteHinhThucMienGiamError(res.message || ""))
    }
}

function* loadHinhThucMienGiamHinhThucTinhWorker(data: HinhThucMienGiamHinhThucTinhLoadStartAction) {
    const res: IBaseResponeModel = yield call(hinhThucMienGiamApi.select_hinhthuctinhs)
    if (res.is_success) {
        yield put(loadHinhThucMienGiamHinhThucTinhSuccessAction(res.data))
    } 
}