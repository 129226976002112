import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_khoannop } from "../models/response/category/sf_khoannop";
import { apiClient } from "./apiClient";

export const SF_KHOANNOP_SELECT_ALL_API = "khoan_nop/select_all";
export const SF_KHOANNOP_INSERT_API = "khoan_nop/insert";
export const SF_KHOANNOP_UPDATE_API = "khoan_nop/update";
export const SF_KHOANNOP_DELETE_API = "khoan_nop/delete";

export const SF_KHOANNOP_SELECT_BY_MIENGIAM = "khoan_nop/select_by_miengiam";

export const khoanNopApi = {
    select_all: () => {
        return apiClient.post(`${SF_KHOANNOP_SELECT_ALL_API}`)
    },
    insert: (data: sf_khoannop) => {
        return apiClient.post(`${SF_KHOANNOP_INSERT_API}`, data)
    },
    update: (data: sf_khoannop) => {
        return apiClient.post(`${SF_KHOANNOP_UPDATE_API}`, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_KHOANNOP_DELETE_API}`, model)
    },
    select_by_miengiam: (sf_hinhthucmiengiam_id: number) => apiClient.post(`${SF_KHOANNOP_SELECT_BY_MIENGIAM}?sf_hinhthucmiengiam_id=${sf_hinhthucmiengiam_id}`,)

}