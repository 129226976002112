import { hoanPhiApi } from './../../api/hoanPhiApi';
import { call, put, takeEvery } from "redux-saga/effects";
import { IBaseResponeModel } from "../../models/response/base-response";
import { hocSinhApi } from './../../api/hocSinhApi';
import { eHoanPhiActionTypeIds, IHoanPhiLoadHocSinhStart, IHoanPhiCalculateStart } from './../action-types/hoanPhiActionTypes';
import { hoanPhiActions } from './../actions/hoanPhiActions';

export function* hoanPhiSaga(): any {
    yield takeEvery(eHoanPhiActionTypeIds.LOAD_HOCSINH_START, loadHocSinhWorker)
    yield takeEvery(eHoanPhiActionTypeIds.CALCULATE_START, calculateWorker)
}
function* loadHocSinhWorker(action: IHoanPhiLoadHocSinhStart): any {
    const res: IBaseResponeModel = yield call(hocSinhApi.select, {
        dm_he_id: action.payload.dm_he_id,
        dm_khoi_id: action.payload.dm_khoi_id,
        dm_truong_id: action.payload.dm_truong_id,
        nam_hoc: action.payload.nam_hoc
    })
    if (res.is_success) {
        yield put(hoanPhiActions.loadHocSinhSuccess(res.data))
    } else {
        yield put(hoanPhiActions.loadHocSinhError(res.message ?? "Có lỗi"))
    }
}

function* calculateWorker(action: IHoanPhiCalculateStart): any {
    const res: IBaseResponeModel = yield call(hoanPhiApi.calculate, action.payload)
    if (res.is_success) {
        yield put(hoanPhiActions.calculateSuccess())
    } else {
        yield put(hoanPhiActions.calculateError(res.message ?? "Có lỗi"))
    }
}
