import { call, put, takeLatest } from "redux-saga/effects";
import { IBaseResponeModel } from "../../models/response/base-response";
import { tbtpApi } from './../../api/tbtpApi';
import { DuKienActionTypeIds, DuKienLoadDataStart } from './../action-types/duKienActionTypes';
import { loadDuKienDataErr, loadDuKienDataSuccess } from './../actions/duKienActions';
export function* duKienSagas(): any {
    yield takeLatest(DuKienActionTypeIds.LOAD_DATASOURCE_START, loadHocSinhStartWorker)


}
function* loadHocSinhStartWorker(data: DuKienLoadDataStart) {
    const res: IBaseResponeModel = yield call(tbtpApi.select_du_kien, data.payload)
    if (res.is_success) {
        yield put(loadDuKienDataSuccess(res.data))
    } else {
        yield put(loadDuKienDataErr(res.message || "Không tải được học sinh"))
    }
}
