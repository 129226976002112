import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter"
import { sf_hachtoan_congno, sf_hachtoan_congno_viewmodel } from "../../models/response/hach-toan-cong-no/sf_hachtoan_congno"
export enum HachToanCongNoActionTypeIds {
    HACHTOANCONGNO_LOAD_START = "HACHTOANCONGNO_LOAD_START",
    HACHTOANCONGNO_LOAD_SUCCESS = "HACHTOANCONGNO_LOAD_SUCCESS",
    HACHTOANCONGNO_LOAD_ERROR = "HACHTOANCONGNO_LOAD_ERROR",

    HACHTOANCONGNO_SHOW_DETAIL_MODAL = "HACHTOANCONGNO_SHOW_DETAIL_MODAL",
    HACHTOANCONGNO_CLOSE_DETAIL_MODAL = "HACHTOANCONGNO_CLOSE_DETAIL_MODAL",

    HACHTOANCONGNO_SHOW_DELETE_CONFIRM = "HACHTOANCONGNO_SHOW_DELETE_CONFIRM",
    HACHTOANCONGNO_CLOSE_DELETE_CONFIRM = "HACHTOANCONGNO_CLOSE_DELETE_CONFIRM",

    HACHTOANCONGNO_SAVE_START = "HACHTOANCONGNO_SAVE_START",
    HACHTOANCONGNO_SAVE_SUCCESS = "HACHTOANCONGNO_SAVE_SUCCESS",
    HACHTOANCONGNO_SAVE_ERROR = "HACHTOANCONGNO_SAVE_ERROR",

    HACHTOANCONGNO_DELETE_START = "HACHTOANCONGNO_DELETE_START",
    HACHTOANCONGNO_DELETE_SUCCESSS = "HACHTOANCONGNO_DELETE_SUCCESSS",
    HACHTOANCONGNO_DELETE_ERROR = "HACHTOANCONGNO_DELETE_ERROR",

    HACHTOANCONGNO_CHANGE_SELECTED_IDS = "HACHTOANCONGNO_CHANGE_SELECTED_IDS",

    HACHTOANCONGNO_CHANGE_FILTER = "HACHTOANCONGNO_CHANGE_FILTER",

    HACHTOANCONGNO_SHOW_IMPORT_MODEL = "HACHTOANCONGNO_SHOW_IMPORT_MODEL",
    HACHTOANCONGNO_CLOSE_IMPORT_MODEL = "HACHTOANCONGNO_CLOSE_IMPORT_MODEL"

}

export interface HachToanCongNoLoadStartAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_LOAD_START,
    payload: NamHocTruongKhoiHeFilter
}
export interface HachToanCongNoLoadSuccessAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_LOAD_SUCCESS,
    payload: sf_hachtoan_congno_viewmodel[]
}
export interface HachToanCongNoLoadErrorAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_LOAD_ERROR,
    payload: string
}

export interface HachToanCongNoShowDetailModalAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SHOW_DETAIL_MODAL,
    payload?: sf_hachtoan_congno_viewmodel
}
export interface HachToanCongNoCloseDetailModalAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_CLOSE_DETAIL_MODAL,
}
export interface HachToanCongNoShowDeleteConfirmAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SHOW_DELETE_CONFIRM,
}
export interface HachToanCongNoCloseDeleteConfirmAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_CLOSE_DELETE_CONFIRM,
}

export interface HachToanCongNoSaveStartAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SAVE_START,
    payload: sf_hachtoan_congno
}
export interface HachToanCongNoSaveSuccessAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SAVE_SUCCESS,
    payload: sf_hachtoan_congno
}
export interface HachToanCongNoSaveErrorAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SAVE_ERROR,
    payload: string
}
export interface HachToanCongNoDeleteStartAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_DELETE_START,
    payload: number[]
}
export interface HachToanCongNoDeleteSuccessAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_DELETE_SUCCESSS,
    payload: number[]
}
export interface HachToanCongNoDeleteErrorAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_DELETE_ERROR,
    payload: string
}

export interface HachToanCongNoChangeSelectedIdsAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_CHANGE_SELECTED_IDS,
    payload: number[]
}

export interface HachToanCongNoChangeFilterAction {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_CHANGE_FILTER,
    payload: NamHocTruongKhoiHeFilter
}


export interface HachToanCongNoShowImportModal {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_SHOW_IMPORT_MODEL,
}



export interface HachToanCongNoCloseImportModal {
    type: HachToanCongNoActionTypeIds.HACHTOANCONGNO_CLOSE_IMPORT_MODEL,
}



export type HachToanCongNoActionTypes =
    HachToanCongNoLoadStartAction | HachToanCongNoLoadSuccessAction | HachToanCongNoLoadErrorAction |
    HachToanCongNoShowDetailModalAction | HachToanCongNoShowDeleteConfirmAction |
    HachToanCongNoCloseDetailModalAction | HachToanCongNoCloseDeleteConfirmAction |
    HachToanCongNoSaveStartAction | HachToanCongNoSaveSuccessAction | HachToanCongNoSaveErrorAction |
    HachToanCongNoDeleteStartAction | HachToanCongNoDeleteSuccessAction | HachToanCongNoDeleteErrorAction |
    HachToanCongNoChangeSelectedIdsAction |
    HachToanCongNoChangeFilterAction |
    HachToanCongNoShowImportModal | HachToanCongNoCloseImportModal