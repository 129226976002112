import { sf_mucphaithu_insert_rq } from "../../models/request/thu-phi/sf_mucphaithu_insert_from_khoannop_rq";
import { sf_mucphaithu_insert_from_tbtp_rq } from "../../models/request/thu-phi/sf_mucphaithu_insert_from_tbtp_rq";
import { sf_mucphaithu_select_byhocsinh_rq } from "../../models/request/thu-phi/sf_mucphaithu_select_byhocsinh_rq";
import { sf_mucphaithu } from "../../models/response/thu-phi/sf_mucphaithu";
import { ThuPhiActionTypeIds, ThuPhiChangeHocSinh, ThuPhiChangeNamHoc, ThuPhiCloseAddModal, ThuPhiCloseChiTiet, ThuPhiCreateFromKhoanNopErr, ThuPhiCreateFromKhoanNopStart, ThuPhiCreateFromKhoanNopSucess, ThuPhiCreatFromTBTPErr, ThuPhiCreatFromTBTPStart, ThuPhiCreatFromTBTPSuccess, ThuPhiMucPhaiThuLoadErrr, ThuPhiMucPhaiThuLoadStart, ThuPhiMucPhaiThuLoadSuccess, ThuPhiShowAddModal, ThuPhiShowChiTiet } from "../action-types/thuPhiActionTypes";

export const changeThuPhiNamHoc = (nam_hoc: string): ThuPhiChangeNamHoc => {
    return {
        type: ThuPhiActionTypeIds.THUPHI_CHANGE_NAMHOC,
        payload: nam_hoc
    }
}


export const changeThuPhiHocSinh = (payload: any): ThuPhiChangeHocSinh => {
    return {
        type: ThuPhiActionTypeIds.THUPHI_CHANGE_HOCSINH,
        payload: payload
    }
}


export const loadThuPhiMucPhaiThuStart = (rq: sf_mucphaithu_select_byhocsinh_rq): ThuPhiMucPhaiThuLoadStart => {
    return {
        type: ThuPhiActionTypeIds.THUPHI_MUCPHAITHU_LOAD_START,
        payload: rq
    }
}

export const loadThuPhiMucPhaiThuSuccess = (data: sf_mucphaithu[]): ThuPhiMucPhaiThuLoadSuccess => {
    return {
        type: ThuPhiActionTypeIds.THUPHI_MUCPHAITHU_LOAD_SUCCESS,
        payload: data
    }
}

export const loadThuPhiMucPhaiThuError = (message: string): ThuPhiMucPhaiThuLoadErrr => {
    return {
        type: ThuPhiActionTypeIds.THUPHI_MUCPHAITHU_LOAD_ERROR,
        payload: message
    }
}

export const createThuPhiFromTBTPStart = (rq: sf_mucphaithu_insert_from_tbtp_rq): ThuPhiCreatFromTBTPStart =>{
    return {
        type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_TBTP_START,
        payload: rq
    }
}

export const createThuPhiFromTBTPSuccess = (): ThuPhiCreatFromTBTPSuccess =>{
    return {
        type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_TBTP_SUCESS,
        
    }
}

export const createThuPhiFromTBTPErr = (message: string): ThuPhiCreatFromTBTPErr =>{
    return {
        type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_TBTP_ERROR,
        payload: message
        
    }
}


export const showThuPhiChiTiet = (data: sf_mucphaithu): ThuPhiShowChiTiet =>{
    return {
        type: ThuPhiActionTypeIds.THUPHI_SHOW_CHITIET,
        payload: data
        
    }
}


export const closeThuPhiChiTiet = (): ThuPhiCloseChiTiet =>{
    return {
        type: ThuPhiActionTypeIds.THUPHI_CLOSE_CHITIET,        
    }
}



export const showThuPhiAddModal = (): ThuPhiShowAddModal =>{
    return {
        type: ThuPhiActionTypeIds.THUPHI_SHOW_ADD_MODAL
        
    }
}


export const closeThuPhiAddModal = (): ThuPhiCloseAddModal =>{
    return {
        type: ThuPhiActionTypeIds.THUPHI_CLOSE_ADD_MODAL,        
    }
}


export const createThuPhiFromKhoanNopStart = (rq: sf_mucphaithu_insert_rq): ThuPhiCreateFromKhoanNopStart =>{
    return {
        type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_START,
        payload: rq
    }
}

export const createThuPhiFromKhoanNopSuccess = (): ThuPhiCreateFromKhoanNopSucess =>{
    return {
        type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_SUCCESS,
        
    }
}

export const createThuPhiFromKhoanNopErr = (message: string): ThuPhiCreateFromKhoanNopErr =>{
    return {
        type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_ERROR,
        payload: message
        
    }
}