import { call, put, takeEvery } from "redux-saga/effects"
import { ganMienGiamApi } from "../../api/ganMienGiamApi"
import { hinhThucMienGiamApi } from "../../api/hinhThucMienGiamApi"
import { hocSinhApi } from "../../api/hocSinhApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { GanMienGiamActionTypeIds, GanMienGiamApproveAllSubmitAction, GanMienGiamChangeStartAction, GanMienGiamLoadHinhThucMienGiamStartAction, GanMienGiamLoadHocSinhStartAction, GanMienGiamLoadStartAction } from "../action-types/ganMienGiamActionTypes"
import { approveGanMienGiamError, approveGanMienGiamSuccess, changeGanMienGiamErr, changeGanMienGiamSuccess, loadGanMienGiamHinhThucMienGiamSuccess, loadGanMienGiamHocSinhSuccess, loadGanMienGiamSuccess } from "../actions/ganMienGiamActions"
export function* ganMienGiamSagas(): any {
    yield takeEvery(GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HOCSINH_START, loadHocSinhStartWorker)
    yield takeEvery(GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_HINHTHUCMIENGIAM_START, loadHinhThucMienGiamWorker)
    yield takeEvery(GanMienGiamActionTypeIds.GAN_MIEN_GIAM_LOAD_START, loadGanMienGiamWorker)
    yield takeEvery(GanMienGiamActionTypeIds.GAN_MIEN_GIAM_CHANGE_START, changeGanMienGiamWorker)
    yield takeEvery(GanMienGiamActionTypeIds.GAN_MIEN_GIAM_APPROVE_ALL_SUBMIT, approveMienGiamWorker)

}
function* loadHocSinhStartWorker(data: GanMienGiamLoadHocSinhStartAction) {
    const res: IBaseResponeModel = yield call(hocSinhApi.select, data.payload)
    if (res.is_success) {
        yield put(loadGanMienGiamHocSinhSuccess(res.data))
    } else {
        yield put(loadGanMienGiamHocSinhSuccess([]))
    }
}
function* loadHinhThucMienGiamWorker(data: GanMienGiamLoadHinhThucMienGiamStartAction) {
    const res: IBaseResponeModel = yield call(hinhThucMienGiamApi.select, data.payload)
    if (res.is_success) {
        yield put(loadGanMienGiamHinhThucMienGiamSuccess(res.data))
    } else {
        yield put(loadGanMienGiamHinhThucMienGiamSuccess([]))
    }
}
function* loadGanMienGiamWorker(data: GanMienGiamLoadStartAction) {
    const res: IBaseResponeModel = yield call(ganMienGiamApi.select, data.payload)
    if (res.is_success) {
        yield put(loadGanMienGiamSuccess(res.data))
    } else {
        yield put(loadGanMienGiamSuccess([]))
    }
}
function* changeGanMienGiamWorker(data: GanMienGiamChangeStartAction) {
    const res: IBaseResponeModel = yield call(ganMienGiamApi.changed, data.payload)
    if (res.is_success) {
        yield put(changeGanMienGiamSuccess(res.data))
    } else {
        yield put(changeGanMienGiamErr(res.message || "Có lỗi"))
    }
}
function* approveMienGiamWorker(data: GanMienGiamApproveAllSubmitAction) {
    const res: IBaseResponeModel = yield call(ganMienGiamApi.approve, data.payload)
    if (res.is_success) {
        yield put(approveGanMienGiamSuccess())
    } else {
        yield put(approveGanMienGiamError(res.message || "Có lỗi"))
    }
}