import { saleSetDichVuApi } from './../../api/saleSetRegisterDichVu';
import { call, put, takeEvery } from "redux-saga/effects";
import { IBaseResponeModel } from "../../models/response/base-response";
import { hocSinhApi } from './../../api/hocSinhApi';
import { eSaleSetDichVuTypeIds, ISaleSetDichVuLoadStart, ISaleSetDichVuLoadHocSinhStart } from './../action-types/saleSetRegisterDichVuActionTypes';
import { saleSetDichVuAction } from './../actions/saleSetRegisterDichVu';

export function* saleSetSaga(): any {
    yield takeEvery(eSaleSetDichVuTypeIds.LOAD_HOCSINH_START, loadHocSinhWorker)
    yield takeEvery(eSaleSetDichVuTypeIds.LOAD_START, loadStart)
}
function* loadStart(action: ISaleSetDichVuLoadStart): any {
    const res: IBaseResponeModel = yield call(saleSetDichVuApi.select, action.payload)
    if (res.is_success) {
        yield put(saleSetDichVuAction.loadSuccess(res.data))
    } else {
        yield put(saleSetDichVuAction.loadError(res.message ?? "Có lỗi"))
    }
}

function* loadHocSinhWorker(action: ISaleSetDichVuLoadHocSinhStart): any {
    const res: IBaseResponeModel = yield call(hocSinhApi.select, {
        dm_he_id: action.payload.dm_he_id,
        dm_khoi_id: action.payload.dm_khoi_id,
        dm_truong_id: action.payload.dm_truong_id,
        nam_hoc: action.payload.nam_hoc
    })
    if (res.is_success) {
        yield put(saleSetDichVuAction.loadHocSinhSuccess(res.data))
    } else {
        yield put(saleSetDichVuAction.loadHocSinhErr(res.message ?? "Có lỗi"))
    }
}


