import { showNotify } from '../../helpers/toast';
import { IHocSinhProfilePageState } from "../page-state-model/hocSinhProfilePageState";
import { PageBaseStatus } from './../../models/pageBaseStatus';
import { eHocSinhProfileActionTypeIds, IHocSinhProfileActionTypes } from './../action-types/hocSinhProfileActionTypes';

const intiState: IHocSinhProfilePageState = {
    filter: {
        nam_hoc: "",
        ts_hocsinh_id: 0,
    },
    sf_saleset_viewmodels: [],
    ts_hocsinh_viewmodel: undefined,
    sf_mucphaithus: [],
    status: PageBaseStatus.is_not_initial,
    isShowThuPhiChiTiet: false,
    isShowThuPhiAddModal: false
}

export const hocSinhProfileReducer = (state: IHocSinhProfilePageState = intiState, action: IHocSinhProfileActionTypes): IHocSinhProfilePageState => {
    switch (action.type) {

        case eHocSinhProfileActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case eHocSinhProfileActionTypeIds.SELECT_SALESET_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case eHocSinhProfileActionTypeIds.SELECT_SALESET_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_saleset_viewmodels: action.payload
            }
        case eHocSinhProfileActionTypeIds.SELECT_SALESET_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_saleset_viewmodels: []
            }
        case eHocSinhProfileActionTypeIds.SELECT_HOCSINH_SUCCESS:
            return {
                ...state,
                ts_hocsinh_viewmodel: action.payload
            }
        case eHocSinhProfileActionTypeIds.SELECT_HOCSINH_ERROR:
            return {
                ...state,
                ts_hocsinh_viewmodel: undefined
            }
        case eHocSinhProfileActionTypeIds.SELECT_CHUNGTU_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case eHocSinhProfileActionTypeIds.SELECT_CHUNGTU_SUCCESS:
            return {
                ...state,
                sf_mucphaithus: action.payload,
                status: PageBaseStatus.is_completed
            }
        case eHocSinhProfileActionTypeIds.SELECT_CHUNGTU_ERROR:
            return {
                ...state,
                sf_mucphaithus: [],
                status: PageBaseStatus.is_completed
            }
        case eHocSinhProfileActionTypeIds.SHOW_THUPHI_CHITIET:
            return {
                ...state,
                isShowThuPhiChiTiet: true,
                sf_mucphaithu_editing: action.payload
            }
        case eHocSinhProfileActionTypeIds.CLOSE_THUPHI_CHITIET:
            return {
                ...state,
                isShowThuPhiChiTiet: false,
                sf_mucphaithu_editing: undefined
            }
        case eHocSinhProfileActionTypeIds.SHOW_THUPHI_ADD_MODAL:
            return {
                ...state,
                isShowThuPhiAddModal: true
            }
        case eHocSinhProfileActionTypeIds.CLOSE_THUPHI_ADD_MODAL:
            return {
                ...state,
                isShowThuPhiAddModal: false
            }
        case eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_need_reload
            }
        case eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }
        case eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_TBTP_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_TBTP_SUCESS:
            return {
                ...state,
                status: PageBaseStatus.is_need_reload
            }
        case eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_TBTP_ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }
        default:
            return {
                ...state
            }
    }
}