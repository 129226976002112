import { sf_mucphaithu_insert_rq } from "../../models/request/thu-phi/sf_mucphaithu_insert_from_khoannop_rq"
import { sf_mucphaithu_insert_from_tbtp_rq } from "../../models/request/thu-phi/sf_mucphaithu_insert_from_tbtp_rq"
import { sf_mucphaithu_select_byhocsinh_rq } from "../../models/request/thu-phi/sf_mucphaithu_select_byhocsinh_rq"
import { sf_mucphaithu } from "../../models/response/thu-phi/sf_mucphaithu"

export enum ThuPhiActionTypeIds {
    THUPHI_CHANGE_NAMHOC = "THUPHI_CHANGE_NAMHOC",
    THUPHI_CHANGE_HOCSINH = "THUPHI_CHANGE_HOCSINH",

    THUPHI_MUCPHAITHU_LOAD_START = "THUPHI_MUCPHAITHU_LOAD_START",
    THUPHI_MUCPHAITHU_LOAD_SUCCESS = "THUPHI_MUCPHAITHU_LOAD_SUCCESS",
    THUPHI_MUCPHAITHU_LOAD_ERROR = "THUPHI_MUCPHAITHU_LOAD_ERROR",

    THUPHI_CREATE_FROM_TBTP_START = "THUPHI_CREATE_FROM_TBTP_START",
    THUPHI_CREATE_FROM_TBTP_SUCESS = "THUPHI_CREATE_FROM_TBTP_SUCESS",
    THUPHI_CREATE_FROM_TBTP_ERROR = "THUPHI_CREATE_FROM_TBTP_ERROR",

    THUPHI_SHOW_CHITIET = "THUPHI_SHOW_CHITIET",
    THUPHI_CLOSE_CHITIET = "THUPHI_CLOSE_CHITIET",


    THUPHI_SHOW_ADD_MODAL = "THUPHI_SHOW_ADD_MODAL",
    THUPHI_CLOSE_ADD_MODAL = "THUPHI_CLOSE_ADD_MODAL",

    THUPHI_CREATE_FROM_KHOANNOP_START = "THUPHI_CREATE_FROM_KHOANNOP_START",
    THUPHI_CREATE_FROM_KHOANNOP_SUCCESS = "THUPHI_CREATE_FROM_KHOANNOP_SUCCESS",
    THUPHI_CREATE_FROM_KHOANNOP_ERROR = "THUPHI_CREATE_FROM_KHOANNOP_ERROR"
}
export interface ThuPhiChangeNamHoc {
    type: ThuPhiActionTypeIds.THUPHI_CHANGE_NAMHOC,
    payload: string
}

export interface ThuPhiChangeHocSinh {
    type: ThuPhiActionTypeIds.THUPHI_CHANGE_HOCSINH,
    payload: any
}


export interface ThuPhiMucPhaiThuLoadStart {
    type: ThuPhiActionTypeIds.THUPHI_MUCPHAITHU_LOAD_START,
    payload: sf_mucphaithu_select_byhocsinh_rq
}


export interface ThuPhiMucPhaiThuLoadSuccess {
    type: ThuPhiActionTypeIds.THUPHI_MUCPHAITHU_LOAD_SUCCESS,
    payload: sf_mucphaithu[]
}
export interface ThuPhiMucPhaiThuLoadErrr {
    type: ThuPhiActionTypeIds.THUPHI_MUCPHAITHU_LOAD_ERROR,
    payload: string
}

export interface ThuPhiCreatFromTBTPStart {
    type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_TBTP_START,
    payload: sf_mucphaithu_insert_from_tbtp_rq
}


export interface ThuPhiCreatFromTBTPSuccess {
    type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_TBTP_SUCESS
}

export interface ThuPhiCreatFromTBTPErr {
    type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_TBTP_ERROR,
    payload: string
}

export interface ThuPhiShowChiTiet {
    type: ThuPhiActionTypeIds.THUPHI_SHOW_CHITIET,
    payload: sf_mucphaithu
}

export interface ThuPhiCloseChiTiet {
    type: ThuPhiActionTypeIds.THUPHI_CLOSE_CHITIET,
}



export interface ThuPhiShowAddModal {
    type: ThuPhiActionTypeIds.THUPHI_SHOW_ADD_MODAL
}

export interface ThuPhiCloseAddModal {
    type: ThuPhiActionTypeIds.THUPHI_CLOSE_ADD_MODAL,
}

export interface ThuPhiCreateFromKhoanNopStart {
    type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_START,
    payload: sf_mucphaithu_insert_rq
}

export interface ThuPhiCreateFromKhoanNopSucess {
    type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_SUCCESS,
}

export interface ThuPhiCreateFromKhoanNopErr {
    type: ThuPhiActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_ERROR,
    payload: string
}

export type ThuPhiActionTypes = ThuPhiChangeNamHoc | ThuPhiChangeHocSinh |
    ThuPhiMucPhaiThuLoadStart | ThuPhiMucPhaiThuLoadSuccess | ThuPhiMucPhaiThuLoadErrr |
    ThuPhiCreatFromTBTPStart | ThuPhiCreatFromTBTPSuccess | ThuPhiCreatFromTBTPErr |
    ThuPhiShowChiTiet | ThuPhiCloseChiTiet |
    ThuPhiShowAddModal | ThuPhiCloseAddModal |
    ThuPhiCreateFromKhoanNopStart | ThuPhiCreateFromKhoanNopSucess | ThuPhiCreateFromKhoanNopErr
