import { call, put, takeEvery } from "redux-saga/effects"
import { hinhThucNopBomApi } from "../../../api/hinhThucNopBom"
import { tbtpApi } from "../../../api/tbtpApi"
import { IBaseResponeModel } from "../../../models/response/base-response"
import {
    EditTBTPKhoanNopActionTypeIds, HinhThucNopBomLoadStart, KhoanNopDeleteStart,
    KhoanNopLoadStart, KhoanNopAddStart
} from "../../action-types/edit-tbtp/editTBTPKhoanNopActionTypes"
import {
    loadHinhThucNopBomError, loadHinhThucNopBomSuccess,
    loadKhoanNopError, loadKhoanNopSuccess, deleteKhoanNopSuccess, deleteKhoanNopError,
    saveKhoanNopSuccess,saveKhoanNopError
} from "../../actions/edit-tbtp/editTBTPKhoanNopActions"


export function* editTBTPKhoanNopSagas(): any {
    yield takeEvery(EditTBTPKhoanNopActionTypeIds.LOAD_KHOANNOP_START, loadKhoanNopStartWorker)
    yield takeEvery(EditTBTPKhoanNopActionTypeIds.LOAD_HINHTHUCNOPBOM_START, loadHinhThucNopBomStartWorker)
    yield takeEvery(EditTBTPKhoanNopActionTypeIds.KHOANNOP_DELETE_START, deleteKhoanNopStartWorker)
    yield takeEvery(EditTBTPKhoanNopActionTypeIds.KHOANNOP_ADD_START, saveKhoanNopStartWorker)
}
function* loadKhoanNopStartWorker(data: KhoanNopLoadStart) {
    const res: IBaseResponeModel = yield call(tbtpApi.select_khoannops, data.payload)
    if (res.is_success) {
        yield put(loadKhoanNopSuccess(res.data))
    } else {
        yield put(loadKhoanNopError(res.message || ""))
    }
}

function* loadHinhThucNopBomStartWorker(data: HinhThucNopBomLoadStart) {
    const res: IBaseResponeModel = yield call(hinhThucNopBomApi.select_by_bom, data.payload)
    if (res.is_success) {
        yield put(loadHinhThucNopBomSuccess(res.data))
    } else {
        yield put(loadHinhThucNopBomError(res.message || ""))
    }
}


function* deleteKhoanNopStartWorker(data: KhoanNopDeleteStart) {
    const res: IBaseResponeModel = yield call(tbtpApi.remove_khoannop, data.payload)
    if (res.is_success) {
        yield put(deleteKhoanNopSuccess())
    } else {
        yield put(deleteKhoanNopError(res.message || ""))
    }
}


function* saveKhoanNopStartWorker(data: KhoanNopAddStart) {
    const res: IBaseResponeModel = yield call(tbtpApi.add_khoannop, data.payload)
    if (res.is_success) {
        yield put(saveKhoanNopSuccess())
    } else {
        yield put(saveKhoanNopError(res.message || ""))
    }
}

