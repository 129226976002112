import { IBaseDeleteRequestModel } from "../models/request/base/deleteRequest";
import { sf_hinhthucmiengiam_copy_rq } from "../models/request/hinhthucmiengiam/sf_hinhthucmiengiam_copy_rq";
import { sf_hinhthucmiengiam } from "../models/response/category/sf_hinhthucmiengiam";
import { apiClient } from "./apiClient";

export const SF_HINHTHUCMIENGIAM_SELECT_API = "sf_hinhthucmiengiam/select";
export const SF_HINHTHUCMIENGIAM_INSERT_API = "sf_hinhthucmiengiam/insert";
export const SF_HINHTHUCMIENGIAM_UPDATE_API = "sf_hinhthucmiengiam/update";
export const SF_HINHTHUCMIENGIAM_DELETE_API = "sf_hinhthucmiengiam/delete";

export const SF_HINHTHUCMIENGIAM_HINHTHUCTINH_SELECT_ALL = "sf_hinhthucmiengiam_hinhthuctinh/select_all";

export const SF_HINHTHUCMIENGIAM_CHITIET_SELECT = "sf_hinhthucmiengiam_chitiet/select_by_hinhthuc";

export const SF_HINHTHUCMIENGIAM_CHITIET_SAVE_CHANGES = "sf_hinhthucmiengiam_chitiet/save_changes";

export const SF_HINHTHUCMIENGIAM_COPY = "sf_hinhthucmiengiam/copy"

export const hinhThucMienGiamApi = {
    select: (nam_hoc: string) => {
        return apiClient.post(`${SF_HINHTHUCMIENGIAM_SELECT_API}?nam_hoc=${nam_hoc}`)
    },
    insert: (data: sf_hinhthucmiengiam) => {
        return apiClient.post(`${SF_HINHTHUCMIENGIAM_INSERT_API}`, data)
    },
    update: (data: sf_hinhthucmiengiam) => {
        return apiClient.post(`${SF_HINHTHUCMIENGIAM_UPDATE_API}`, data)
    },
    delete: (data: number[]) => {
        const model: IBaseDeleteRequestModel = {
            ids: data
        }
        return apiClient.post(`${SF_HINHTHUCMIENGIAM_DELETE_API}`, model)
    },
    select_hinhthuctinhs: () => apiClient.post(SF_HINHTHUCMIENGIAM_HINHTHUCTINH_SELECT_ALL),

    select_chitiets: (sf_hinhthucmiengiam_id: number) => apiClient.post(`${SF_HINHTHUCMIENGIAM_CHITIET_SELECT}?sf_hinhthucmiengiam_id=${sf_hinhthucmiengiam_id}`),

    save_change_chitiets: (data: any) => apiClient.post(`${SF_HINHTHUCMIENGIAM_CHITIET_SAVE_CHANGES}`, data),

    copy: (payload: sf_hinhthucmiengiam_copy_rq) => apiClient.post(`${SF_HINHTHUCMIENGIAM_COPY}`, payload)

}