import React, { useState, createContext, useContext, useEffect } from 'react';

const NavigationContext = createContext({} as NavigationType);
const useNavigation = () => useContext(NavigationContext);
type NavigationProviderProps = {
  children: React.ReactNode
}
type NavigationType = {
  navigationData: any,
  setNavigationData: any
}

function NavigationProvider({ children }: NavigationProviderProps) {
  const [navigationData, setNavigationData] = useState({});

  return (
    <NavigationContext.Provider value={{ navigationData, setNavigationData }}>
      {children}
    </NavigationContext.Provider>
  );
}

function withNavigationWatcher(Component: any) {
  return function (props: any) {
    const { path } = props.match;
    const { setNavigationData }: NavigationType = useNavigation();

    useEffect(() => {
      setNavigationData({ currentPath: path });
    }, [path, setNavigationData]);

    return React.createElement(Component, props);
  }
}

export {
  NavigationProvider,
  useNavigation,
  withNavigationWatcher
}
