import { ISalseSetSelectByStudentRequest } from '../../models/request/sale-set/SaleSetSelectByStudentRequest';
import { sf_mucphaithu_insert_rq } from '../../models/request/thu-phi/sf_mucphaithu_insert_from_khoannop_rq';
import { sf_mucphaithu_insert_from_tbtp_rq } from '../../models/request/thu-phi/sf_mucphaithu_insert_from_tbtp_rq';
import { sf_saleset_viewmodel } from '../../models/response/sale-set/sf_saleset';
import { eHocSinhProfileActionTypeIds } from "../action-types/hocSinhProfileActionTypes";
import { sf_mucphaithu_select_byhocsinh_rq } from './../../models/request/thu-phi/sf_mucphaithu_select_byhocsinh_rq';
import { ts_hocsinh_viewmodel } from './../../models/response/hocsinh/ts_hocsinh';
import { sf_mucphaithu } from './../../models/response/thu-phi/sf_mucphaithu';
import { baseAction } from "./BaseActions";

export const hocSinhProfileActions = {
    selectSaleSetStart: (request: ISalseSetSelectByStudentRequest) => baseAction(eHocSinhProfileActionTypeIds.SELECT_SALESET_START, request),
    selectSaleSetSuccess: (respone: sf_saleset_viewmodel[]) => baseAction(eHocSinhProfileActionTypeIds.SELECT_SALESET_SUCCESS, respone),
    selectSaleSetErr: (err: string) => baseAction(eHocSinhProfileActionTypeIds.SELECT_SALESET_ERROR, err),

    selectHocSinhStart: (ts_hocsinh_id: number) => baseAction(eHocSinhProfileActionTypeIds.SELECT_HOCSINH_START, ts_hocsinh_id),
    selectHocSinhSuccess: (ts_hocsinh_viewmodel: ts_hocsinh_viewmodel) => baseAction(eHocSinhProfileActionTypeIds.SELECT_HOCSINH_SUCCESS, ts_hocsinh_viewmodel),
    selectHocSinhError: (err: string) => baseAction(eHocSinhProfileActionTypeIds.SELECT_HOCSINH_ERROR, err),

    selectChungTuStart: (data: sf_mucphaithu_select_byhocsinh_rq) => baseAction(eHocSinhProfileActionTypeIds.SELECT_CHUNGTU_START, data),
    selectChungTuSuccess: (data: sf_mucphaithu[]) => baseAction(eHocSinhProfileActionTypeIds.SELECT_CHUNGTU_SUCCESS, data),
    selectChungTuError: (err: string) => baseAction(eHocSinhProfileActionTypeIds.SELECT_CHUNGTU_ERROR, err),

    showThuPhiChiTiet: (data: sf_mucphaithu) => baseAction(eHocSinhProfileActionTypeIds.SHOW_THUPHI_CHITIET, data),
    closeThuPhiChiTiet: () => baseAction(eHocSinhProfileActionTypeIds.CLOSE_THUPHI_CHITIET, undefined),

    showThuPhiAddModal: () => baseAction(eHocSinhProfileActionTypeIds.SHOW_THUPHI_ADD_MODAL, undefined),
    closeThuPhiAddModal: () => baseAction(eHocSinhProfileActionTypeIds.CLOSE_THUPHI_ADD_MODAL, undefined),

    createThuPhiFromKhoanNopStart: (request: sf_mucphaithu_insert_rq) => baseAction(eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_START, request),
    createThuPhiFromKhoanNopSuccess: () => baseAction(eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_SUCCESS, undefined),
    createThuPhiFromKhoanNopError: (err: string) => baseAction(eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_KHOANNOP_ERROR, err),

    createThuPhiFromTBTPStart: (request: sf_mucphaithu_insert_from_tbtp_rq) => baseAction(eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_TBTP_START, request),
    createThuPhiFromTBTPSuccess: () => baseAction(eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_TBTP_SUCESS, undefined),
    createThuPhiFromTBTPError: (err: string) => baseAction(eHocSinhProfileActionTypeIds.THUPHI_CREATE_FROM_TBTP_ERROR, err),

    changeFilter: (request: ISalseSetSelectByStudentRequest) => baseAction(eHocSinhProfileActionTypeIds.CHANGE_FILTER, request)
}