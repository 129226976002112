import { call, put, takeEvery } from "redux-saga/effects";
import { dictionaryApi } from "../../api/dictionaryApi";
import { IBaseResponeModel } from "../../models/response/base-response";
import { DictionaryActionTypeIds } from "../action-types/dictionaryActionTypes";
import { setDictionary } from "../actions/dictionaryAction";

export function* dictionarySaga(): any {
    yield takeEvery(DictionaryActionTypeIds.LOAD_DICTIONARY_REQUESTED, loadDictionaryWorker)
}
function* loadDictionaryWorker(): any {
    const res: IBaseResponeModel = yield call(dictionaryApi.getDictionary)
    if (res.is_success) {
        yield put(setDictionary(res.data))
    }
}
