
export enum CommonActionTypeIds {
    HEADER_COMBOBOX_NAMHOC_SHOW = "HEADER_COMBOBOX_NAMHOC_SHOW",
    HEADER_COMBOBOX_NAMHOC_CLOSE = "HEADER_COMBOBOX_NAMHOC_CLOSE",
    HEADER_COMBOBOX_NAMHOC_CHANGED = "HEADER_COMBOBOX_NAMHOC_CHANGED",
    HEADER_COMBOBOX_TRUONG_CHANGED = "HEADER_COMBOBOX_TRUONG_CHANGED",
    HEADER_COMBOBOX_TRUONG_SHOW = "HEADER_COMBOBOX_TRUONG_SHOW",
    HEADER_COMBOBOX_TRUONG_CLOSE = "HEADER_COMBOBOX_TRUONG_CLOSE",
}



export interface HeaderComboboxNamHocShowAction {
    type: CommonActionTypeIds.HEADER_COMBOBOX_NAMHOC_SHOW,
}
export interface HeaderComboboxNamHocChangedAction {
    type: CommonActionTypeIds.HEADER_COMBOBOX_NAMHOC_CHANGED,
    payload: string
}

export interface HeaderComboboxNamHocCloseAction {
    type: CommonActionTypeIds.HEADER_COMBOBOX_NAMHOC_CLOSE,
}

export interface HeaderComboboxTruongShowAction {
    type: CommonActionTypeIds.HEADER_COMBOBOX_TRUONG_SHOW
}
export interface HeaderComboboxTruongChangedAction {
    type: CommonActionTypeIds.HEADER_COMBOBOX_TRUONG_CHANGED,
    payload: number
}
export interface HeaderComboboxTruongCloseAction {
    type: CommonActionTypeIds.HEADER_COMBOBOX_TRUONG_CLOSE
}


export type CommonActionTypes =
    HeaderComboboxNamHocShowAction | HeaderComboboxNamHocChangedAction | HeaderComboboxNamHocCloseAction |
    HeaderComboboxTruongChangedAction | HeaderComboboxTruongShowAction | HeaderComboboxTruongCloseAction