import { sf_bom_copy_rq } from "../../models/request/bom/sf_bom_copy_rq"
import { sf_bom_selecte_rq } from "../../models/request/bom/sf_bom_select_rq"
import { sf_bom } from "../../models/response/bom/sf_bom"
export enum BOMActionTypeIds {
    BOM_LOAD_START = "BOM_LOAD_START",
    BOM_LOAD_SUCCESS = "BOM_LOAD_SUCCESS",
    BOM_LOAD_ERROR = "BOM_LOAD_ERROR",

    BOM_SHOW_DETAIL_MODAL = "BOM_SHOW_DETAIL_MODAL",
    BOM_CLOSE_DETAIL_MODAL = "BOM_CLOSE_DETAIL_MODAL",

    BOM_SHOW_DELETE_CONFIRM = "BOM_SHOW_DELETE_CONFIRM",
    BOM_CLOSE_DELETE_CONFIRM = "BOM_CLOSE_DELETE_CONFIRM",

    BOM_SAVE_START = "BOM_SAVE_START",
    BOM_SAVE_SUCCESS = "BOM_SAVE_SUCCESS",
    BOM_SAVE_ERROR = "BOM_SAVE_ERROR",

    BOM_DELETE_START = "BOM_DELETE_START",
    BOM_DELETE_SUCCESSS = "BOM_DELETE_SUCCESSS",
    BOM_DELETE_ERROR = "BOM_DELETE_ERROR",

    BOM_CHANGE_SELECTED_IDS = "BOM_CHANGE_SELECTED_IDS",

    BOM_NAMHOC_CHANGED = "BOM_NAMHOC_CHANGED",
    BOM_TRUONG_CHANGED = "BOM_TRUONG_CHANGED",
    BOM_KHOI_CHANGED = "BOM_KHOI_CHANGED",
    BOM_HE_CHANGED = "BOM_HE_CHANGED",

    BOM_SHOW_BOMCHIETIET_MODAL=" BOM_SHOW_BOMCHIETIET_MODAL",
    BOM_CLOSE_BOMCHITIET_MODAL="BOM_CLOSE_BOMCHITIET_MODAL",

    BOM_SHOW_COPY_MODAL="BOM_SHOW_COPY_MODAL",
    BOM_CLOSE_COPY_MODAL="BOM_CLOSE_COPY_MODAL",

    BOM_SHOW_COPY_NAMHOC_MODAL="BOM_SHOW_COPY_NAMHOC_MODAL",
    BOM_CLOSE_COPY_NAMHOC_MODAL="BOM_CLOSE_COPY_NAMHOC_MODAL",

    BOM_COPY_START="BOM_COPY_START",
    BOM_COPY_SUCCESS="BOM_COPY_SUCCESS",
    BOM_COPY_ERR="BOM_COPY_ERR"


    

}

export interface BOMLoadStartAction {
    type: BOMActionTypeIds.BOM_LOAD_START,
    payload: sf_bom_selecte_rq
}
export interface BOMLoadSuccessAction {
    type: BOMActionTypeIds.BOM_LOAD_SUCCESS,
    payload: sf_bom[]
}
export interface BOMLoadErrorAction {
    type: BOMActionTypeIds.BOM_LOAD_ERROR,
    payload: string
}

export interface BOMShowDetailModalAction {
    type: BOMActionTypeIds.BOM_SHOW_DETAIL_MODAL,
    payload?: sf_bom
}
export interface BOMCloseDetailModalAction {
    type: BOMActionTypeIds.BOM_CLOSE_DETAIL_MODAL,
}
export interface BOMShowDeleteConfirmAction {
    type: BOMActionTypeIds.BOM_SHOW_DELETE_CONFIRM,
}
export interface BOMCloseDeleteConfirmAction {
    type: BOMActionTypeIds.BOM_CLOSE_DELETE_CONFIRM,
}

export interface BOMSaveStartAction {
    type: BOMActionTypeIds.BOM_SAVE_START,
    payload: sf_bom
}
export interface BOMSaveSuccessAction {
    type: BOMActionTypeIds.BOM_SAVE_SUCCESS,
    payload: sf_bom
}
export interface BOMSaveErrorAction {
    type: BOMActionTypeIds.BOM_SAVE_ERROR,
    payload: string
}
export interface BOMDeleteStartAction {
    type: BOMActionTypeIds.BOM_DELETE_START,
    payload: number[]
}
export interface BOMDeleteSuccessAction {
    type: BOMActionTypeIds.BOM_DELETE_SUCCESSS,
    payload: number[]
}
export interface BOMDeleteErrorAction {
    type: BOMActionTypeIds.BOM_DELETE_ERROR,
    payload: string
}

export interface BOMChangeSelectedIdsAction {
    type: BOMActionTypeIds.BOM_CHANGE_SELECTED_IDS,
    payload: number[]
}
export interface BOMNamHocChangedAction {
    type: BOMActionTypeIds.BOM_NAMHOC_CHANGED,
    payload: string
}
export interface BOMTruongChangedAction {
    type: BOMActionTypeIds.BOM_TRUONG_CHANGED,
    payload: number
}
export interface BOMKhoiChangedAction {
    type: BOMActionTypeIds.BOM_KHOI_CHANGED,
    payload: number
}
export interface BOMHeChangedAction {
    type: BOMActionTypeIds.BOM_HE_CHANGED,
    payload: number
}

export interface BOMShowBomChiTietModalAction{
    type: BOMActionTypeIds.BOM_SHOW_BOMCHIETIET_MODAL,
    payload: sf_bom
}
export interface BOMCloseBomChiTietModalAction{
    type: BOMActionTypeIds.BOM_CLOSE_BOMCHITIET_MODAL
}

export interface BOMShowCopyModalAction{
    type: BOMActionTypeIds.BOM_SHOW_COPY_MODAL,
    payload: sf_bom
}
export interface BOMCloseCopyModal{
    type: BOMActionTypeIds.BOM_CLOSE_COPY_MODAL
}


export interface BOMShowCopyNamHocModalAction{
    type: BOMActionTypeIds.BOM_SHOW_COPY_NAMHOC_MODAL,
}
export interface BOMCloseCopyNamHocModal{
    type: BOMActionTypeIds.BOM_CLOSE_COPY_NAMHOC_MODAL
}

export interface BOMCopyStartAction {
    type: BOMActionTypeIds.BOM_COPY_START,
    payload: sf_bom_copy_rq
}
export interface BOMCopySuccessAction {
    type: BOMActionTypeIds.BOM_COPY_SUCCESS
}
export interface BOMCopyErrAction {
    type: BOMActionTypeIds.BOM_COPY_ERR,
    payload: string
}

export type BOMActionTypes =
    BOMLoadStartAction | BOMLoadSuccessAction | BOMLoadErrorAction |
    BOMShowDetailModalAction | BOMShowDeleteConfirmAction |
    BOMCloseDetailModalAction | BOMCloseDeleteConfirmAction |
    BOMSaveStartAction | BOMSaveSuccessAction | BOMSaveErrorAction |
    BOMDeleteStartAction | BOMDeleteSuccessAction | BOMDeleteErrorAction |
    BOMChangeSelectedIdsAction |
    BOMNamHocChangedAction | BOMTruongChangedAction | BOMKhoiChangedAction | BOMHeChangedAction|
    BOMShowBomChiTietModalAction | BOMCloseBomChiTietModalAction |
    BOMShowCopyModalAction |BOMCloseCopyModal |
    BOMShowCopyNamHocModalAction| BOMCloseCopyNamHocModal|
    BOMCopyStartAction | BOMCopySuccessAction | BOMCopyErrAction