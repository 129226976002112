import { sf_mucthuphi_savechanges_request } from '../../models/request/mucthuphi/sf_mucthuphi_savechanges_request';
import { MucThuPhiNgsActionTypeIds, MucThuPhiNGsChangeFilter, MucThuPhiNgsCloseSaleSetConfirm, MucThuPhiNgsCreateSaleSetError, MucThuPhiNgsCreateSaleSetStart, MucThuPhiNgsCreateSaleSetSuccess, MucThuPhiNgsSavingError } from "../action-types/mucThuPhiNgsActionTypes";
import { NamHocTruongKhoiHeFilter } from './../../models/namHocTruongKhoiHeFilter';
import { sf_mucthuphi_viewmodel } from './../../models/response/category/sf_mucthuphi';
import { MucThuPhiNgsCloseAddKhoanNopModal, MucThuPhiNgsLoadError, MucThuPhiNgsLoadStart, MucThuPhiNgsLoadSuccess, MucThuPhiNgsSavingStart, MucThuPhiNgsSavingSuccess, MucThuPhiNgsShowAddKhoanNopModal, MucThuPhiNgsShowRemvoKhoanNopModal, MucThuPhiNgsCloseRemvoKhoanNopModal, MucThuPhiNgsShowSaleSetConfirm } from './../action-types/mucThuPhiNgsActionTypes';

export const changeMucThuPhiNgsFilter = (filter: NamHocTruongKhoiHeFilter): MucThuPhiNGsChangeFilter => {
    return {
        type: MucThuPhiNgsActionTypeIds.CHANGE_FILTER,
        payload: filter
    }
}

export const showMucThuPhiNgsAddKhoanNopModal = (): MucThuPhiNgsShowAddKhoanNopModal => {
    return {
        type: MucThuPhiNgsActionTypeIds.SHOW_ADD_KHOANNOP_MODAL
    }
}


export const closeMucThuPhiNgsAddKhoanNopModal = (): MucThuPhiNgsCloseAddKhoanNopModal => {
    return {
        type: MucThuPhiNgsActionTypeIds.CLOSE_ADD_KHOANNOP_MODAL
    }
}

export const loadMucThuPhiNgsStart = (filter: NamHocTruongKhoiHeFilter): MucThuPhiNgsLoadStart => {
    return {
        type: MucThuPhiNgsActionTypeIds.LOAD_START,
        payload: filter
    }
}
export const loadMucThuPhiNgsSuccess = (data: sf_mucthuphi_viewmodel[]): MucThuPhiNgsLoadSuccess => {
    return {
        type: MucThuPhiNgsActionTypeIds.LOAD_SUCCESS,
        payload: data
    }
}
export const loadMucThuPhiNgsError = (message: string): MucThuPhiNgsLoadError => {
    return {
        type: MucThuPhiNgsActionTypeIds.LOAD_ERROR,
        payload: message
    }
}
export const savingMucThuPhiNgsStart = (request: sf_mucthuphi_savechanges_request[]): MucThuPhiNgsSavingStart => {
    return {
        type: MucThuPhiNgsActionTypeIds.SAVING_START,
        payload: request
    }
}
export const savingMucThuPhiNgsSuccess = (): MucThuPhiNgsSavingSuccess => {
    return {
        type: MucThuPhiNgsActionTypeIds.SAVING_SUCCESS
    }
}
export const savingMucThuPhiNgsError = (message: string): MucThuPhiNgsSavingError => {
    return {
        type: MucThuPhiNgsActionTypeIds.SAVING_ERROR,
        payload: message
    }
}

export const showMucThuPhiNgsRemoveKhoanNopModal = (): MucThuPhiNgsShowRemvoKhoanNopModal =>{
    return {
        type: MucThuPhiNgsActionTypeIds.SHOW_REMOVE_KHOANNOP_MODAL
    }
}
export const closeMucThuPhiNgsRemoveKhoanNopModal = (): MucThuPhiNgsCloseRemvoKhoanNopModal =>{
    return {
        type: MucThuPhiNgsActionTypeIds.CLOSE_REMOVE_KHOANNOP_MODAL
    }
}

export const showSaleSetConfirm = (): MucThuPhiNgsShowSaleSetConfirm =>{
    return {
        type: MucThuPhiNgsActionTypeIds.SHOW_SALESET_CONFIRM
    }
}
export const closeSaleSetConfirm = (): MucThuPhiNgsCloseSaleSetConfirm =>{
    return {
        type: MucThuPhiNgsActionTypeIds.CLOSE_SALESET_CONFIRM
    }
}
export const createSaleSetStart = (filter: NamHocTruongKhoiHeFilter): MucThuPhiNgsCreateSaleSetStart =>{
    return {
        type: MucThuPhiNgsActionTypeIds.CREATE_SALE_SET_START,
        payload: filter
    }
}

export const createSaleSetSuccess = (): MucThuPhiNgsCreateSaleSetSuccess =>{
    return {
        type: MucThuPhiNgsActionTypeIds.CREATE_SALE_SET_SUCCESS,
    }
}
export const createSaleSetError = (): MucThuPhiNgsCreateSaleSetError =>{
    return {
        type: MucThuPhiNgsActionTypeIds.CREATE_SALE_SET_ERROR,
    }
}