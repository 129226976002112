import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { LoaiKhoanNopActionTypeIds, LoaiKhoanNopActionTypes } from "../action-types/loaiKhoanNopActionTypes";
import { LoaiKhoanNopPageState } from "../page-state-model/loaiKhoanNopPageState";
const initialState: LoaiKhoanNopPageState = {
    sf_loaikhoannops: [],
    sf_loaikhoannop_selected_ids: [],
    status: PageBaseStatus.is_completed
}
export const loaiKhoanNopReducer = (state: LoaiKhoanNopPageState = initialState, action: LoaiKhoanNopActionTypes): LoaiKhoanNopPageState => {
    switch (action.type) {
        case LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_loaikhoannops: action.payload
            }
        case LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case LoaiKhoanNopActionTypeIds.KHOANOP_SHOW_DETAIL_MODAL:
            return {
                ...state,
                is_show_detail_modal: action.payload
            }
        case LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: action.payload
            }
        case LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SAVE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SAVE_SUCCESS:
            showNotify({
                message: `${action.payload.id > 0 ? "Cập nhật " : "Thêm mới "} thành công loại khoản nộp ${action.payload.loai_khoan_nop}`,
                type: "success"
            })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_loaikhoannop_editing: undefined
            }
        case LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SAVE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }

        case LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} khoản nộp`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                is_show_delete_confirm: false
            }
        case LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_loaikhoannop_selected_ids: action.payload
            }
        default:
            return state;
    }
}