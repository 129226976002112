import { toast } from 'react-toastify';
type NotifyInputType = {
    message: string,
    type: "info" | "warning" | "success" | "error" | "default",
    displayTime?: number

}
type NotifyErrorInputType = {
    message: string,
    displayTime?: number

}
type NotifySuccessInputType = {
    message: string,
    displayTime?: number

}
export const showNotify = (props: NotifyInputType) => {
    toast(props.message, {
        type: props.type,
        hideProgressBar: true,
        autoClose: props.displayTime || 3000,
        position: "bottom-center",
        theme: "colored"
    })
}
export const showErrNotify = (props: NotifyErrorInputType) => {
    showNotify({ type: "error", message: props.message, displayTime: props.displayTime })
}
export const showSuccessNotify = (props: NotifySuccessInputType) => {
    showNotify({ type: "success", message: props.message, displayTime: props.displayTime })
}