import { call, put, takeEvery } from "redux-saga/effects";
import { IBaseResponeModel } from "../../models/response/base-response";
import { hoanTraTienAnApi } from './../../api/hoanTraTienAnApi';
import { HoanTraTienAnActionTypeIds, HoanTraTienAnDeleteStartAction, HoanTraTienAnLoadStartAction } from './../action-types/hoanTraTienAnActionTypes';
import { deleteHoanTraTienAnError, deleteHoanTraTienAnSuccess, loadHoanTraTienAnError, loadHoanTraTienAnSuccess } from './../actions/hoanTraTienAnActions';
export function* hoanTraTienAnSagas(): any {
    yield takeEvery(HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_LOAD_START, loadWorker)
    yield takeEvery(HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_DELETE_START, deleteWorker)
}
function* loadWorker(data: HoanTraTienAnLoadStartAction) {
    const res: IBaseResponeModel = yield call(hoanTraTienAnApi.select, data.payload)
    if (res.is_success) {
        yield put(loadHoanTraTienAnSuccess(res.data))
    } else {
        yield put(loadHoanTraTienAnError(res.message || ""))
    }
}

function* deleteWorker(data: HoanTraTienAnDeleteStartAction) {
    const res: IBaseResponeModel = yield call(hoanTraTienAnApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteHoanTraTienAnSuccess(data.payload))
    } else {
        yield put(deleteHoanTraTienAnError(res.message || ""))
    }
}