import { call, put, takeEvery } from "redux-saga/effects"
import { nhomKhoanNopApi } from "../../api/nhomKhoanNopApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { NhomKhoanNopActionTypeIds, NhomKhoanNopDeleteStartAction, NhomKhoanNopSaveStartAction } from "../action-types/nhomKhoanNopActionTypes"
import {
    loadNhomKhoanNopError, loadNhomKhoanNopSuccess, saveNhomKhoanNopError, saveNhomKhoanNopSuccess,
    deleteNhomKhoanNopSuccess, deleteNhomKhoanNopError
} from "../actions/nhomKhoanNopActions"
export function* nhomKhoanNopSaga(): any {
    yield takeEvery(NhomKhoanNopActionTypeIds.NHOMKHOANNOP_LOAD_START, loadNhomKhoanNopStartWorker)
    yield takeEvery(NhomKhoanNopActionTypeIds.NHOMKHOANNOP_SAVE_START, saveNhomKhoanNopStartWorker)
    yield takeEvery(NhomKhoanNopActionTypeIds.NHOMKHOANNOP_DELETE_START, deleteNhomKhoanNopStartWorker)
}
function* loadNhomKhoanNopStartWorker() {
    const res: IBaseResponeModel = yield call(nhomKhoanNopApi.select_all)
    if (res.is_success) {
        yield put(loadNhomKhoanNopSuccess(res.data))
    } else {
        yield put(loadNhomKhoanNopError(res.message || ""))
    }
}
function* saveNhomKhoanNopStartWorker(data: NhomKhoanNopSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(nhomKhoanNopApi.update, data.payload)
    } else {
        res = yield call(nhomKhoanNopApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveNhomKhoanNopSuccess(data.payload))
        yield call(loadNhomKhoanNopStartWorker)
    } else {
        yield put(saveNhomKhoanNopError(res.message || ""))
    }
}
function* deleteNhomKhoanNopStartWorker(data: NhomKhoanNopDeleteStartAction) {
    const res: IBaseResponeModel = yield call(nhomKhoanNopApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteNhomKhoanNopSuccess(data.payload))
        yield call(loadNhomKhoanNopStartWorker)
    } else {
        yield put(deleteNhomKhoanNopError(res.message || ""))
    }
}