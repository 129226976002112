import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { ThuPhiChiTietActionTypeIds, ThuPhiChiTietActionTypes } from "../action-types/thuPhiChiTietActionTypes";
import { ThuPhiChiTietPageState } from "../page-state-model/thuPhiChiTietPageState"

const initialState: ThuPhiChiTietPageState = {
    status: PageBaseStatus.is_completed
}

export const thuPhiChiTietReducer = (state: ThuPhiChiTietPageState = initialState, action: ThuPhiChiTietActionTypes): ThuPhiChiTietPageState => {
    switch (action.type) {
        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_INITIAL:
            return {
                ...action.payload
            }
        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_mucphaithu_chitiets: action.payload
            }
        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_ERROR:
            showNotify({ type: "warning", message: action.payload })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }

        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MIENGIAM_LOAD_SUCCESS:
            return {
                ...state,
                sf_mucphaithu_miengiams: action.payload
            }

        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MIENGIAM_CHITIET_LOAD_SUCCESS:
            return {
                ...state,
                sf_mucphaithu_chitiet_miengiams: action.payload
            }
        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_confirm_delete: true,
                sf_mucphaithu_chitiet_selected: action.payload
            }
        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_CLOSE_DELETE_CONFIRM:
            return {
                ...state,
                is_show_confirm_delete: false,
                sf_mucphaithu_chitiet_selected: undefined
            }
        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_DELETE_KHOANNOP_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_DELETE_KHOANNOP_SUCCESS:
            showNotify({ message: "Đã bỏ khoản nộp", type: "success" })
            return {
                ...state,
                is_show_confirm_delete: false,
                status: PageBaseStatus.is_need_reload
            }
        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_DELETE_KHOANNOP_ERR:
            showNotify({ message: "Không bỏ được khoản nộp", type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }

       
       
        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_PHANBOPHI_SUCCESS:
            return {
                ...state,
                sf_phanbophis: action.payload

            }
        
        case ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MUCPHAITHU_LOAD_SUCCESS:
            return {
                ...state,
                sf_mucphaithu: action.payload
            }
      
      
        default:
            return state;
    }
}