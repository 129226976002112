import { all } from "redux-saga/effects"
import accountSaga from "./accountSaga"
import { authSaga } from "./authSaga"
import { bomChiTietSaga } from "./bomChiTietSagas"
import { bomSaga } from "./bomSagas"
import { categorySourceSaga } from "./categorySourceSagas"
import { dictionarySaga } from "./dictionarySagas"
import { duKienSagas } from "./duKienSagas"
import { editTBTPHachToanSagas } from "./edit-tbtp/editTBTPHachToanSagas"
import { editTBTPKhoanNopSagas } from "./edit-tbtp/editTBTPKhoanNopSagas"
import { ganMienGiamSagas } from "./ganMienGiamSagas"
import { hachToanCongNoSagas } from "./hachToanCongNoSagas"
import { hinhThucMienGiamSaga } from "./hinhThucMienGiamSagas"
import { khoanNopGroupSaga } from "./khoanNopGroupSagas"
import { khoanNopSaga } from "./khoanNopSaga"
import { loaiKhoanNopSaga } from "./loaiKhoanNopSagas"
import { mucThuPhiNgsSagas } from "./mucThuPhiNgsSagas"
import { mucThuPhiSaga } from "./mucThuPhiSagas"
import { nhomKhoanNopSaga } from "./nhomKhoanNopSagas"
import { hocSinhProfileSaga } from "./hocSinhProfileSaga"
import { tbtpSagas } from "./tbtpSagas"
import { thuPhiChiTietSagas } from "./thuPhiChiTietSagas"
import { thuPhiSagas } from "./thuPhiSagas"
import { tongHopChiTietSaga } from "./tongHopChiTietSaga"
import { tongHopSaga } from "./tongHopSaga"
import { hoanTraTienAnSagas } from "./hoanTraTienAnSagas"
import { hoanPhiSaga } from "./hoanPhiSaga"
import { saleSetSaga } from "./saleSetDichVuSaga"
export default function* rootSaga() {
    yield all([
        accountSaga(),
        authSaga(),
        dictionarySaga(),
        khoanNopSaga(),
        nhomKhoanNopSaga(),
        loaiKhoanNopSaga(),
        khoanNopGroupSaga(),
        categorySourceSaga(),
        mucThuPhiSaga(),
        bomSaga(),
        bomChiTietSaga(),
        tbtpSagas(),
        hinhThucMienGiamSaga(),
        ganMienGiamSagas(),
        hachToanCongNoSagas(),
        editTBTPHachToanSagas(),
        editTBTPKhoanNopSagas(),
        tongHopSaga(),
        tongHopChiTietSaga(),
        thuPhiSagas(),
        thuPhiChiTietSagas(),
        mucThuPhiNgsSagas(),
        duKienSagas(),
        hocSinhProfileSaga(),
        hoanTraTienAnSagas(),
        hoanPhiSaga(),
        saleSetSaga()
    ])

}
