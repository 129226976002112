import { call, put, takeEvery } from "redux-saga/effects"
import { phanBoPhiApi } from "../../api/phanBoPhiApi"
import { thuPhiApi } from "../../api/thuPhiApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { ThuPhiChiTietActionTypeIds, ThuPhiChiTietDeleteKhoanNopStart, ThuPhiChiTietLoadStart, ThuPhiChiTietMienGiamLoadStart, ThuPhiChiTietMucPhaiThuLoadStart, ThuPhiChiTietPhanBoPhiLoadStart } from "../action-types/thuPhiChiTietActionTypes"
import { deleteThuPhiKhoanNopError, deleteThuPhiKhoanNopSuccess, loadThuPhiChiTietError, loadThuPhiChiTietMucPhaiThuSuccess, loadThuPhiChiTietSuccess, loadThuPhiMienGiamChiTietSuccess, loadThuPhiMienGiamSuccess, loadThuPhiPhanBoPhiError, loadThuPhiPhanBoPhiSuccess } from "../actions/thuPhiChiTietAction"
export function* thuPhiChiTietSagas(): any {
    yield takeEvery(ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_START, loadStart)
    yield takeEvery(ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_DELETE_KHOANNOP_START, deleteKhoanNopStart)
    yield takeEvery(ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MIENGIAM_LOAD_START, loadMienGiamWorker)
    yield takeEvery(ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_LOAD_PHANBOPHI_START, loadPhanBoPhiWorker)
    yield takeEvery(ThuPhiChiTietActionTypeIds.THUPHI_CHITIET_MUCPHAITHU_LOAD_START, loadMucPhaiThuWorker)

}

function* loadMucPhaiThuWorker(data: ThuPhiChiTietMucPhaiThuLoadStart) {
    const res: IBaseResponeModel = yield call(thuPhiApi.select_by_sct, data.payload)
    if (res.is_success) {
        yield put(loadThuPhiChiTietMucPhaiThuSuccess(res.data))
    } 

}
function* loadPhanBoPhiWorker(data: ThuPhiChiTietPhanBoPhiLoadStart) {
    const res: IBaseResponeModel = yield call(phanBoPhiApi.select_by_sct, data.payload)
    if (res.is_success) {
        yield put(loadThuPhiPhanBoPhiSuccess(res.data))
    } else {
        yield put(loadThuPhiPhanBoPhiError(res.message || "Không tải được dữ liệu"))
    }

}
function* deleteKhoanNopStart(data: ThuPhiChiTietDeleteKhoanNopStart) {
    const res: IBaseResponeModel = yield call(thuPhiApi.remove_khoannop, data.payload.id)
    if (res.is_success) {
        yield put(deleteThuPhiKhoanNopSuccess())
    } else {
        yield put(deleteThuPhiKhoanNopError(res.message || "Không tải được dữ liệu"))
    }

}

function* loadMienGiamWorker(data: ThuPhiChiTietMienGiamLoadStart) {
    const res_miengiam: IBaseResponeModel = yield call(thuPhiApi.select_miengiam, data.payload)
    const res_miengiam_chitiet: IBaseResponeModel = yield call(thuPhiApi.select_miengiam_chitiet, data.payload)


    if (res_miengiam.is_success) {
        yield put(loadThuPhiMienGiamSuccess(res_miengiam.data))
    } else {
        yield put(loadThuPhiMienGiamSuccess([]))
    }

    if (res_miengiam_chitiet.is_success) {
        yield put(loadThuPhiMienGiamChiTietSuccess(res_miengiam_chitiet.data))
    } else {
        yield put(loadThuPhiMienGiamChiTietSuccess([]))
    }

}


function* loadStart(data: ThuPhiChiTietLoadStart) {
    const res: IBaseResponeModel = yield call(thuPhiApi.select_chitiet, data.payload)
    if (res.is_success) {
        yield put(loadThuPhiChiTietSuccess(res.data))
    } else {
        yield put(loadThuPhiChiTietError(res.message || "Không tải được dữ liệu"))
    }

}
