import { CategorySourceActionTypeIds, CategorySourceActionTypes } from "../action-types/categorySourceActionTypes"
import { CategorySourceState } from "../page-state-model/categorySourceState"

const initialState: CategorySourceState = {
    dm_hes: [],
    dm_khois: [],
    dm_namhocs: [],
    dm_truongs: [],
    dm_truongs_treeview: [],
    dm_truong_khoi_hes: [],
    sf_hinhthucnopboms: []
}

export const categorySourceReducer = (state: CategorySourceState = initialState, action: CategorySourceActionTypes): CategorySourceState => {
    switch (action.type) {
        case CategorySourceActionTypeIds.DM_HE_LOAD_SUCCESS:
            return {
                ...state,
                dm_hes: action.payload
            }
        case CategorySourceActionTypeIds.DM_KHOI_LOAD_SUCCESS:
            return {
                ...state,
                dm_khois: action.payload
            }
        case CategorySourceActionTypeIds.DM_NAMHOC_LOAD_SUCESS:
            return {
                ...state,
                dm_namhocs: action.payload
            }
        case CategorySourceActionTypeIds.DM_TRUONG_LOAD_SUCCESS:
            return {
                ...state,
                dm_truongs: action.payload
            }
        case CategorySourceActionTypeIds.DM_TRUONG_TREEVIEW_LOAD_SUCCESS:
            return {
                ...state,
                dm_truongs_treeview: action.payload
            }
        case CategorySourceActionTypeIds.DM_TRUONG_KHOI_HE_LOAD_SUCESS:
            return {
                ...state,
                dm_truong_khoi_hes: action.payload
            }
        case CategorySourceActionTypeIds.SF_HINHTHUCNOPBOM_LOAD_SUCCESS:
            return {
                ...state,
                sf_hinhthucnopboms: action.payload
            }

        default:
            return state;
    }
}