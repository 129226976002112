import { HoanTraTienAnSelectRequest } from './../../models/request/hoan-tra-tien-an/HoanTraTienAnSelectRequest';
import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter"
import { sf_hoantra_tienan, sf_hoantra_tienan_viewmodel } from "../../models/response/hoan-tra-tien-an/sf_hoantra_tienan"
export enum HoanTraTienAnActionTypeIds {
    HOANTRA_TIENAN_LOAD_START = "HOANTRA_TIENAN_LOAD_START",
    HOANTRA_TIENAN_LOAD_SUCCESS = "HOANTRA_TIENAN_LOAD_SUCCESS",
    HOANTRA_TIENAN_LOAD_ERROR = "HOANTRA_TIENAN_LOAD_ERROR",

    HOANTRA_TIENAN_SHOW_DETAIL_MODAL = "HOANTRA_TIENAN_SHOW_DETAIL_MODAL",
    HOANTRA_TIENAN_CLOSE_DETAIL_MODAL = "HOANTRA_TIENAN_CLOSE_DETAIL_MODAL",

    HOANTRA_TIENAN_SHOW_DELETE_CONFIRM = "HOANTRA_TIENAN_SHOW_DELETE_CONFIRM",
    HOANTRA_TIENAN_CLOSE_DELETE_CONFIRM = "HOANTRA_TIENAN_CLOSE_DELETE_CONFIRM",

    // HOANTRA_TIENAN_SAVE_START = "HOANTRA_TIENAN_SAVE_START",
    // HOANTRA_TIENAN_SAVE_SUCCESS = "HOANTRA_TIENAN_SAVE_SUCCESS",
    // HOANTRA_TIENAN_SAVE_ERROR = "HOANTRA_TIENAN_SAVE_ERROR",

    HOANTRA_TIENAN_DELETE_START = "HOANTRA_TIENAN_DELETE_START",
    HOANTRA_TIENAN_DELETE_SUCCESSS = "HOANTRA_TIENAN_DELETE_SUCCESSS",
    HOANTRA_TIENAN_DELETE_ERROR = "HOANTRA_TIENAN_DELETE_ERROR",

    HOANTRA_TIENAN_CHANGE_SELECTED_IDS = "HOANTRA_TIENAN_CHANGE_SELECTED_IDS",

    HOANTRA_TIENAN_CHANGE_FILTER = "HOANTRA_TIENAN_CHANGE_FILTER",

    HOANTRA_TIENAN_SHOW_IMPORT_MODEL = "HOANTRA_TIENAN_SHOW_IMPORT_MODEL",
    HOANTRA_TIENAN_CLOSE_IMPORT_MODEL = "HOANTRA_TIENAN_CLOSE_IMPORT_MODEL"

}

export interface HoanTraTienAnLoadStartAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_LOAD_START,
    payload: HoanTraTienAnSelectRequest
}
export interface HoanTraTienAnLoadSuccessAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_LOAD_SUCCESS,
    payload: sf_hoantra_tienan_viewmodel[]
}
export interface HoanTraTienAnLoadErrorAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_LOAD_ERROR,
    payload: string
}

export interface HoanTraTienAnShowDetailModalAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_SHOW_DETAIL_MODAL,
    payload?: sf_hoantra_tienan_viewmodel
}
export interface HoanTraTienAnCloseDetailModalAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CLOSE_DETAIL_MODAL,
}
export interface HoanTraTienAnShowDeleteConfirmAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_SHOW_DELETE_CONFIRM,
}
export interface HoanTraTienAnCloseDeleteConfirmAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CLOSE_DELETE_CONFIRM,
}

// export interface HoanTraTienAnSaveStartAction {
//     type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_SAVE_START,
//     payload: sf_hachtoan_congno
// }
// export interface HoanTraTienAnSaveSuccessAction {
//     type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_SAVE_SUCCESS,
//     payload: sf_hachtoan_congno
// }
// export interface HoanTraTienAnSaveErrorAction {
//     type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_SAVE_ERROR,
//     payload: string
// }
export interface HoanTraTienAnDeleteStartAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_DELETE_START,
    payload: number[]
}
export interface HoanTraTienAnDeleteSuccessAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_DELETE_SUCCESSS,
    payload: number[]
}
export interface HoanTraTienAnDeleteErrorAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_DELETE_ERROR,
    payload: string
}

export interface HoanTraTienAnChangeSelectedIdsAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CHANGE_SELECTED_IDS,
    payload: number[]
}

export interface HoanTraTienAnChangeFilterAction {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CHANGE_FILTER,
    payload: HoanTraTienAnSelectRequest
}


export interface HoanTraTienAnShowImportModal {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_SHOW_IMPORT_MODEL,
}



export interface HoanTraTienAnCloseImportModal {
    type: HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CLOSE_IMPORT_MODEL,
}



export type HoanTraTienAnActionTypes =
    HoanTraTienAnLoadStartAction | HoanTraTienAnLoadSuccessAction | HoanTraTienAnLoadErrorAction |
    HoanTraTienAnShowDetailModalAction | HoanTraTienAnShowDeleteConfirmAction |
    HoanTraTienAnCloseDetailModalAction | HoanTraTienAnCloseDeleteConfirmAction |
    // HoanTraTienAnSaveStartAction | HoanTraTienAnSaveSuccessAction | HoanTraTienAnSaveErrorAction |
    HoanTraTienAnDeleteStartAction | HoanTraTienAnDeleteSuccessAction | HoanTraTienAnDeleteErrorAction |
    HoanTraTienAnChangeSelectedIdsAction |
    HoanTraTienAnChangeFilterAction |
    HoanTraTienAnShowImportModal | HoanTraTienAnCloseImportModal