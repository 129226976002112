import { call, put, takeEvery } from "redux-saga/effects"
import { bomApi } from "../../api/bomApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { BOMActionTypeIds, BOMCopyStartAction, BOMDeleteStartAction, BOMLoadStartAction, BOMSaveStartAction } from "../action-types/bomActionTypes"
import {
    loadBOMError, loadBOMSuccess, saveBOMError, saveBOMSuccess,
    deleteBOMSuccess, deleteBOMError, copyBomSuccess, copyBomErr
} from "../actions/bomActionts"
export function* bomSaga(): any {
    yield takeEvery(BOMActionTypeIds.BOM_LOAD_START, loadBOMStartWorker)
    yield takeEvery(BOMActionTypeIds.BOM_SAVE_START, saveBOMStartWorker)
    yield takeEvery(BOMActionTypeIds.BOM_DELETE_START, deleteBOMStartWorker)
    yield takeEvery(BOMActionTypeIds.BOM_COPY_START, copyBomStartWorker)
}
function* loadBOMStartWorker(data: BOMLoadStartAction) {
    const res: IBaseResponeModel = yield call(bomApi.select,data.payload)
    if (res.is_success) {
        yield put(loadBOMSuccess(res.data))
    } else {
        yield put(loadBOMError(res.message || ""))
    }
}
function* saveBOMStartWorker(data: BOMSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(bomApi.update, data.payload)
    } else {
        res = yield call(bomApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveBOMSuccess(data.payload))
    } else {
        yield put(saveBOMError(res.message || ""))
    }
}
function* deleteBOMStartWorker(data: BOMDeleteStartAction) {
    const res: IBaseResponeModel = yield call(bomApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteBOMSuccess(data.payload))
    } else {
        yield put(deleteBOMError(res.message || ""))
    }
}
function* copyBomStartWorker(data: BOMCopyStartAction) {
    const res: IBaseResponeModel = yield call(bomApi.copy, data.payload)
    if (res.is_success) {
        yield put(copyBomSuccess())
    } else {
        yield put(copyBomErr(res.message || ""))
    }
}