import { sf_tbtp_dukien_viewmodal } from './../../models/response/tbtp/sf_tbtp_dukien_viewmodal';
import { NamHocTruongKhoiHeFilter } from "../../models/namHocTruongKhoiHeFilter";
import { DuKienActionTypeIds, DuKienChangeFilter, DuKienLoadDataStart } from "../action-types/duKienActionTypes";
import { ts_hocsinh } from './../../models/response/hocsinh/ts_hocsinh';
import { DuKienLoadDataError, DuKienLoadDataSuccess } from './../action-types/duKienActionTypes';

export const changeDuKienFilter = (filter: NamHocTruongKhoiHeFilter): DuKienChangeFilter => {
    return {
        type: DuKienActionTypeIds.CHANGE_FILTER,
        payload: filter
    }
}
export const loadDuKienDataStart = (filter: NamHocTruongKhoiHeFilter): DuKienLoadDataStart => {
    return {
        type: DuKienActionTypeIds.LOAD_DATASOURCE_START,
        payload: filter
    }
}
export const loadDuKienDataSuccess = (sf_tbtp_dukien_viewmodal: sf_tbtp_dukien_viewmodal): DuKienLoadDataSuccess => {
    return {
        type: DuKienActionTypeIds.LOAD_DATASOURCE_SUCCESS,
        payload: sf_tbtp_dukien_viewmodal
    }
}
export const loadDuKienDataErr = (message: string): DuKienLoadDataError => {
    return {
        type: DuKienActionTypeIds.LOAD_DATASOURCE_ERROR,
        payload: message
    }
}