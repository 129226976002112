import { showNotify } from './../../helpers/toast';
import { localStorageHelper } from "../../helpers/localStorage";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { eHoanPhiActionTypeIds, IHoanPhiActionTypes } from "../action-types/hoanPhiActionTypes";
import { IHoanPhiPageState } from "../page-state-model/hoanPhiPageState";

const iniState: IHoanPhiPageState = {
    filter: {
        nam_hoc: localStorageHelper.getNamHoc(),
        dm_he_id: localStorageHelper.getDmHeId(),
        dm_khoi_id: localStorageHelper.getDmKhoiId(),
        dm_truong_id: localStorageHelper.getDmTruongId(),
    },
    hocSinhs: [],
    status: PageBaseStatus.is_not_initial,
    hocSinhSelectedIds: []
}

export const hoanPhiReducer = (state: IHoanPhiPageState = iniState, action: IHoanPhiActionTypes): IHoanPhiPageState => {
    switch (action.type) {
        case eHoanPhiActionTypeIds.CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case eHoanPhiActionTypeIds.CHANGE_HINHTHUCNOPBOM:
            return {
                ...state,
                hinhThucNopBomSelected: action.payload
            }
        case eHoanPhiActionTypeIds.LOAD_HOCSINH_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case eHoanPhiActionTypeIds.LOAD_HOCSINH_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                hocSinhs: action.payload
            }
        case eHoanPhiActionTypeIds.LOAD_HOCSINH_ERROR:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                hocSinhs: []
            }
        case eHoanPhiActionTypeIds.CHANGE_HOCSINH_SELECTED_IDS:
            return {
                ...state,
                hocSinhSelectedIds: action.payload
            }
        case eHoanPhiActionTypeIds.CALCULATE_START:
            return {
                ...state,
                status: PageBaseStatus.is_saving
            }
        case eHoanPhiActionTypeIds.CALCULATE_SUCCESS:
            showNotify({ type: "success", message: "Cập nhật thành công" })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }
        case eHoanPhiActionTypeIds.CALCULATE_ERROR:
            showNotify({ type: "error", message: "Cập nhật thất bại" })
            return {
                ...state,
                status: PageBaseStatus.is_completed
            }
        default:
            return { ...state }
    }
}