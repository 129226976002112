import { call, put, takeEvery } from "redux-saga/effects"
import { loaiKhoanNopApi } from "../../api/loaiKhoanNopApi"
import { IBaseResponeModel } from "../../models/response/base-response"
import { LoaiKhoanNopActionTypeIds, LoaiKhoanNopDeleteStartAction, LoaiKhoanNopSaveStartAction } from "../action-types/loaiKhoanNopActionTypes"
import {
    loadLoaiKhoanNopError, loadLoaiKhoanNopSuccess, saveLoaiKhoanNopError, saveLoaiKhoanNopSuccess,
    deleteLoaiKhoanNopSuccess, deleteLoaiKhoanNopError
} from "../actions/loaiKhoanNopActions"
export function* loaiKhoanNopSaga(): any {
    yield takeEvery(LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_LOAD_START, loadLoaiKhoanNopStartWorker)
    yield takeEvery(LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_SAVE_START, saveLoaiKhoanNopStartWorker)
    yield takeEvery(LoaiKhoanNopActionTypeIds.LOAIKHOANNOP_DELETE_START, deleteLoaiKhoanNopStartWorker)
}
function* loadLoaiKhoanNopStartWorker() {
    const res: IBaseResponeModel = yield call(loaiKhoanNopApi.select_all)
    if (res.is_success) {
        yield put(loadLoaiKhoanNopSuccess(res.data))
    } else {
        yield put(loadLoaiKhoanNopError(res.message || ""))
    }
}
function* saveLoaiKhoanNopStartWorker(data: LoaiKhoanNopSaveStartAction) {
    let res: IBaseResponeModel;
    if (data.payload.id > 0) {
        res = yield call(loaiKhoanNopApi.update, data.payload)
    } else {
        res = yield call(loaiKhoanNopApi.insert, data.payload)
    }

    if (res.is_success) {
        yield put(saveLoaiKhoanNopSuccess(data.payload))
        yield call(loadLoaiKhoanNopStartWorker)
    } else {
        yield put(saveLoaiKhoanNopError(res.message || ""))
    }
}
function* deleteLoaiKhoanNopStartWorker(data: LoaiKhoanNopDeleteStartAction) {
    const res: IBaseResponeModel = yield call(loaiKhoanNopApi.delete, data.payload)
    if (res.is_success) {
        yield put(deleteLoaiKhoanNopSuccess(data.payload))
        yield call(loadLoaiKhoanNopStartWorker)
    } else {
        yield put(deleteLoaiKhoanNopError(res.message || ""))
    }
}