import { localStorageHelper } from "../../helpers/localStorage";
import { showNotify } from "../../helpers/toast";
import { PageBaseStatus } from "../../models/pageBaseStatus";
import { HoanTraTienAnActionTypeIds } from "../action-types/hoanTraTienAnActionTypes";
import { HoanTraTienAnPageState } from "../page-state-model/hoanTraTienAnhPageState";
import { HoanTraTienAnActionTypes } from './../action-types/hoanTraTienAnActionTypes';
const initialState: HoanTraTienAnPageState = {
    sf_hoantra_tienan_selected_ids: [],
    sf_hoantra_tienan_viewmodels: [],
    status: PageBaseStatus.is_completed,
    filter: {
        nam_hoc: localStorageHelper.getNamHoc(),
        dm_he_id: localStorageHelper.getDmHeId(),
        dm_khoi_id: localStorageHelper.getDmKhoiId(),
        dm_truong_id: localStorageHelper.getDmTruongId(),
        thang: new Date().getMonth() + 1
    }
}
export const hoanTraTienAnReducer = (state: HoanTraTienAnPageState = initialState, action: HoanTraTienAnActionTypes): HoanTraTienAnPageState => {
    switch (action.type) {
        case HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_LOAD_START:
            return {
                ...state,
                status: PageBaseStatus.is_loading
            }
        case HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_LOAD_SUCCESS:
            return {
                ...state,
                status: PageBaseStatus.is_completed,
                sf_hoantra_tienan_viewmodels: action.payload
            }
        case HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_LOAD_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        
        case HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_SHOW_DELETE_CONFIRM:
            return {
                ...state,
                is_show_delete_confirm: true
            }
        
        case HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_DELETE_START:
            return {
                ...state,
                status: PageBaseStatus.is_deleting
            }
        case HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_DELETE_SUCCESSS:
            showNotify({ message: `Xóa thành công ${action.payload.length} khoản nộp`, type: "success" })
            return {
                ...state,
                status: PageBaseStatus.is_need_reload,
                is_show_delete_confirm: false
            }
        case HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_DELETE_ERROR:
            showNotify({ message: action.payload, type: "error" })
            return {
                ...state,
                status: PageBaseStatus.is_completed,
            }
        case HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CHANGE_SELECTED_IDS:
            return {
                ...state,
                sf_hoantra_tienan_selected_ids: action.payload
            }
        case HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CHANGE_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_SHOW_IMPORT_MODEL:
            return {
                ...state,
                is_show_import_modal: true
            }
        case HoanTraTienAnActionTypeIds.HOANTRA_TIENAN_CLOSE_IMPORT_MODEL:
            return {
                ...state,
                is_show_import_modal: false
            }
        default:
            return state;
    }
}